export interface PieChartProps {
  id: string;
  labelGroup?: string;
  data: { name: string; value: number }[];
  donut?: boolean;
  half?: boolean;
  unit?: string;
  labelFontSize?: string;
  height?: string;
}

export const PieChartSampleData: PieChartProps["data"] = [
  { name: "Emergency Response", value: 37000000000 },
  { name: "Basic", value: 24000000000 },
  { name: "Government & Civil Society", value: 24000000000 },
  { name: "Employment Creation", value: 18000000000 },
  { name: "Immigration", value: 15000000000 },
  { name: "Medical Services", value: 12000000000 },
  { name: "Social Protection", value: 10000000000 },
  { name: "Solar Energy ", value: 9000000000 },
  { name: "Other", value: 9000000000 },
];
