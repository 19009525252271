import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { HeaderViewCommonBlockItem } from "app/components/header/sub-components/expanded-view/data";
import { ReactComponent as YourDataHubIcon } from "app/assets/vectors/HeaderProductViewYourDataHub.svg";
import { ReactComponent as AIDAExplorerIcon } from "app/assets/vectors/HeaderProducsViewAIDAExplorer.svg";
import { ReactComponent as SectorExplorerIcon } from "app/assets/vectors/HeaderProductViewSectorExplorer.svg";
import { ReactComponent as YourFavouritesIcon } from "app/assets/vectors/HeaderProductViewYourFavourites.svg";
import { ReactComponent as LocationExplorerIcon } from "app/assets/vectors/HeaderProductViewLocationExplorer.svg";
import { ReactComponent as AIDAIATIPublisherIcon } from "app/assets/vectors/HeaderProductViewAIDAIATIPublisher.svg";
import { ReactComponent as ActivitiesExplorerIcon } from "app/assets/vectors/HeaderProductViewActivitiesExplorer.svg";
import { ReactComponent as OrganisationExplorerIcon } from "app/assets/vectors/HeaderProductViewOrganisationExplorer.svg";

const PublicationTools = [
  {
    title: "AIDA IATI Publisher",
    icon: AIDAIATIPublisherIcon,
    subtitle: "Publish your data to IATI registry with ease.",
  },
  {
    title: "Your Data Hub",
    icon: YourDataHubIcon,
    subtitle: "See your data visualised all in one place.",
  },
];

const DataDiscoveryTools = [
  {
    title: "AIDA Explorer",
    icon: AIDAExplorerIcon,
    subtitle: "See IATI data with visualisations & insights.",
  },
  {
    title: "Organisation Explorer",
    icon: OrganisationExplorerIcon,
    subtitle: "See Organisation-Based Insights.",
  },
  {
    title: "Activities Explorer",
    icon: ActivitiesExplorerIcon,
    subtitle: "See and filter activities.",
  },
  {
    title: "Location Explorer",
    icon: LocationExplorerIcon,
    subtitle: "See Location-Based Insights.",
  },
  {
    title: "Sector Explorer",
    icon: SectorExplorerIcon,
    subtitle: "See Sector-Based Insights.",
  },
  {
    title: "Your Favourites",
    icon: YourFavouritesIcon,
    subtitle: "See your favourite activities.",
  },
];

export const HeaderProductView: React.FC = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        "> div": {
          paddingTop: "0px !important",
        },
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        sx={{
          borderRight: "1px solid #A1AEBD",
        }}
      >
        <Typography variant="h5" fontSize="14px" marginBottom="24px">
          Publication Tools
        </Typography>
        <Grid container spacing={2}>
          {PublicationTools.map((item) => (
            <Grid item xs={12} key={item.title}>
              <HeaderViewCommonBlockItem
                title={item.title}
                icon={<item.icon />}
                subtitle={item.subtitle}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        sx={{
          borderRight: "1px solid #A1AEBD",
        }}
      >
        <Typography variant="h5" fontSize="14px" marginBottom="24px">
          Data Discovery Tools
        </Typography>
        <Grid container spacing={2}>
          {DataDiscoveryTools.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.title}>
              <HeaderViewCommonBlockItem
                title={item.title}
                icon={<item.icon />}
                subtitle={item.subtitle}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        sx={{
          a: {
            display: "block",
            textDecoration: "none",
            color: "#000",
            marginBottom: "16px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography variant="h5" fontSize="14px" marginBottom="24px">
          You might be interested in
        </Typography>
        <Link to="/data-services">
          <Typography
            variant="h5"
            fontSize="14px"
            fontWeight="400"
            marginBottom="10px"
          >
            Data Services
          </Typography>
        </Link>
        <Link to="/user-guide">
          <Typography variant="h5" fontSize="14px" fontWeight="400">
            User Guide
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
