import React from "react";

export const FavouriteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        d="M17.5 11.0225V17.9925L12.5 15.8525L7.5 17.9925V5.02246H13.5V3.02246H7.5C6.4 3.02246 5.5 3.92246 5.5 5.02246V21.0225L12.5 18.0225L19.5 21.0225V11.0225H17.5ZM21.5 7.02246H19.5V9.02246H17.5V7.02246H15.5V5.02246H17.5V3.02246H19.5V5.02246H21.5V7.02246Z"
        fill="#0C162A"
      />
    </svg>
  );
};

export const FavouriteIconSelected = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.5 3H7.5C6.4 3 5.51 3.9 5.51 5L5.5 21L12.5 18L19.5 21V5C19.5 3.9 18.6 3 17.5 3Z"
        fill="#ED6060"
      />
    </svg>
  );
};
