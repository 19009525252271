import React from "react";
import random from "lodash/random";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { CircleFlag } from "react-circle-flags";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { GeomapChart } from "app/components/charts/geomap";
import { GeomapSampleData } from "app/components/charts/geomap/data";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { LOCATION_COLUMNS } from "app/pages/explorer/pages/locations/tableColumns";
import { ReactComponent as ExplorerLocationsHeaderIcon } from "app/assets/vectors/ExplorerLocationsHeaderIcon.svg";

export const ExplorerLocations: React.FC = () => {
  const [countries, setCountries] = React.useState<
    { code: string; name: string }[]
  >([]);

  const LOCATIONS = React.useMemo(() => {
    return countries.map((c: any) => ({
      id: c.code,
      code: c.code,
      name: c.name,
      location: c.name,
      incomingFunds: `USD ${random(1, 10)} MLN`,
      activities: random(10, 10000),
      organisations: random(1, 100),
      sectors: random(1, 50),
    }));
  }, [countries]);

  React.useEffect(() => {
    if (countries.length === 0) {
      fetch("/static/countries.json")
        .then((res) => res.json())
        .then((data) => setCountries(data))
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="AIDA Explorer: Discover Locations"
        subtitle="Embark on a journey to discover the vast landscape of humanitarian efforts around the world. With AIDA's location-specific data, you'll gain insights into how aid is distributed and utilized in various nations. "
        icon={<ExplorerLocationsHeaderIcon />}
      />
      <Box height={40} />
      <Typography variant="h2" fontSize="36px" lineHeight="normal">
        Explore the Location of the Month
      </Typography>
      <Box height={40} />
      <ExploreListBlockItem
        icon={<CircleFlag countryCode="af" height="22" width="22" />}
        title="LOCATION OF THE MONTH"
        description="Afghanistan"
        leftItems={[
          {
            title: "Overview",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  USD 226 billion
                </span>{" "}
                was disbursed to Afghanistan in 2022.
              </React.Fragment>
            ),
          },
          {
            title: "Results",
            description: (
              <React.Fragment>
                In 2022, available results show activities surpassed about{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  4.8%
                </span>{" "}
                of their target results on average.
              </React.Fragment>
            ),
          },
          {
            title: "SUSTAINABLE DEVELOPMENT GOALS",
            description: (
              <React.Fragment>
                In 2022,{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  125,000 activities{" "}
                </span>
                were contributing to the SDGs.
              </React.Fragment>
            ),
          },
          {
            title: "NETWORKS",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  1528 organisations{" "}
                </span>
                have published data on their development and humanitarian
                resources and activities.
              </React.Fragment>
            ),
          },
          {
            title: "FUNDING Analysis",
            description: (
              <React.Fragment>
                Funding gaps analysis by sectors shows that{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  Productive Sectors{" "}
                </span>
                were the least funded sectors of 2022.
              </React.Fragment>
            ),
          },
        ]}
        rightContent={{
          title: "GEOSPATIAL DATA",
          description: (
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                30% of the activities
              </span>{" "}
              have provided exact locations.
            </React.Fragment>
          ),
          content: (
            <GeomapChart
              height="319px"
              data={GeomapSampleData}
              zoom={8}
              focusCountry="Afghanistan"
            />
          ),
        }}
        buttonText="See the Location Page"
        buttonLink="/explorer/locations/AF"
      />
      <Box height={40} />
      <Typography variant="h2" fontSize="36px" lineHeight="normal">
        Explore All Locations & Search
      </Typography>
      <Box height={40} />
      <ExploreListAllBlock columns={LOCATION_COLUMNS} rows={LOCATIONS} />
    </Box>
  );
};
