import React from "react";

export const ShareIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#DFE3E6" />
      <rect width="28" height="28" transform="translate(6 6)" fill="#DFE3E6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.445 23.7686C23.9717 23.7701 24.4881 23.9153 24.9383 24.1886H24.9267C26.2842 24.9983 26.7452 26.7448 25.9641 28.1189C25.1831 29.4931 23.4467 29.9907 22.0564 29.2386C20.6661 28.4866 20.1323 26.761 20.855 25.3553L17.4367 21.8669C17.1898 22.0172 16.9227 22.1312 16.6433 22.2053C16.4108 22.265 16.1718 22.2963 15.9317 22.2986C14.4515 22.3061 13.2004 21.2037 13.0216 19.7343C12.8427 18.265 13.7929 16.8947 15.2317 16.5469C15.4608 16.4911 15.6959 16.4636 15.9317 16.4653C16.5338 16.4657 17.1208 16.6533 17.6117 17.0019L20.855 13.7586C20.3718 12.8557 20.4018 11.7647 20.934 10.8898C21.4661 10.0149 22.4211 9.48645 23.445 9.50026C23.9707 9.49761 24.4871 9.63882 24.9383 9.9086C25.8553 10.4129 26.4336 11.3682 26.4553 12.4145C26.477 13.4608 25.9389 14.4392 25.0436 14.9811C24.1484 15.5231 23.032 15.5463 22.115 15.0419L18.685 18.4719C18.7231 18.5548 18.7543 18.6406 18.7783 18.7286C18.9333 19.3565 18.8717 20.0184 18.6033 20.6069L22.1033 24.1069C22.5178 23.8903 22.9774 23.7744 23.445 23.7686ZM23.445 11.2503C23.0354 11.2479 22.6546 11.4604 22.4417 11.8103C22.2826 12.077 22.2364 12.3961 22.3133 12.6969C22.3807 12.9973 22.5712 13.2556 22.8383 13.4086C23.0172 13.5195 23.2228 13.58 23.4333 13.5836C23.8452 13.5835 24.2266 13.3662 24.4367 13.0119C24.7672 12.4608 24.5899 11.7462 24.04 11.4136C23.8614 11.3033 23.6549 11.2466 23.445 11.2503ZM15.9317 20.5486C15.3825 20.5646 14.8966 20.1953 14.765 19.6619C14.6915 19.3595 14.742 19.0403 14.905 18.7753C15.0641 18.5137 15.3197 18.3251 15.6167 18.2503H15.8967C16.4458 18.2343 16.9317 18.6035 17.0633 19.1369C17.137 19.4341 17.091 19.7484 16.935 20.0119L16.865 20.0936C16.7102 20.315 16.4834 20.4758 16.2233 20.5486H15.9317ZM23.4333 27.7936C23.8429 27.796 24.2237 27.5834 24.4367 27.2336C24.729 26.6957 24.5558 26.0234 24.04 25.6936C23.8611 25.5826 23.6555 25.5222 23.445 25.5186C23.0331 25.5187 22.6517 25.7359 22.4417 26.0903C22.1111 26.6414 22.2885 27.356 22.8383 27.6886C23.0229 27.7786 23.2291 27.815 23.4333 27.7936Z"
        fill="#70777E"
      />
    </svg>
  );
};
