import React from "react";

export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9999 4.5835C10.4936 4.5835 10.0832 4.9939 10.0832 5.50016V10.0835H5.49989C4.99363 10.0835 4.58322 10.4939 4.58322 11.0002C4.58322 11.5064 4.99363 11.9168 5.49989 11.9168H10.0832V16.5002C10.0832 17.0064 10.4936 17.4168 10.9999 17.4168C11.5061 17.4168 11.9166 17.0064 11.9166 16.5002V11.9168H16.4999C17.0062 11.9168 17.4166 11.5064 17.4166 11.0002C17.4166 10.4939 17.0062 10.0835 16.4999 10.0835H11.9166V5.50016C11.9166 4.9939 11.5061 4.5835 10.9999 4.5835Z"
        fill="white"
      />
    </svg>
  );
};
