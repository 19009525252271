import React from "react";
import { Box } from "@mui/material";
import { Hero } from "./components/hero";
import { IatiDataInNumbers } from "./components/iati-in-numbers";
import { LiveDataUpdates } from "./components/live-data-updates";

export const ExplorerLanding = () => {
  return (
    <React.Fragment>
      <Hero />
      <Box height={50} />
      <IatiDataInNumbers />
      <Box height={50} />
      <LiveDataUpdates />
    </React.Fragment>
  );
};
