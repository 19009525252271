import React from "react";

export const CtaOrganisationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="68"
      viewBox="0 0 75 68"
      fill="none"
      {...props}
    >
      <path
        d="M1.35834 28.9442C-0.0585362 30.546 0.584351 32.7255 2.66835 33.1753C4.30012 33.5273 6.54309 33.9202 9.56461 34.2867C9.49277 36.7052 9.44894 39.5113 9.44894 42.7586C9.44894 51.4157 9.76041 56.9381 10.0841 60.3398C10.3811 63.4607 12.4983 65.8382 15.5736 66.3466C19.5711 67.0077 26.4884 67.7138 37.7988 67.7138C49.109 67.7138 56.0265 67.0077 60.024 66.3466C63.0992 65.8382 65.2165 63.4607 65.5133 60.3398C65.8372 56.9381 66.1486 51.4157 66.1486 42.7586C66.1486 39.5117 66.1048 36.7056 66.0329 34.287C69.0555 33.9205 71.299 33.5276 72.9314 33.1755C75.0158 32.7259 75.6587 30.5456 74.2414 28.9435C71.7545 26.1326 67.1305 21.3406 59.0629 14.2384C49.7203 6.01396 43.456 2.43612 40.271 0.952601C38.6873 0.214924 36.9138 0.21503 35.3301 0.952848C32.1456 2.43651 25.882 6.01443 16.5383 14.2384C8.46858 21.3411 3.84461 26.1333 1.35834 28.9442Z"
        fill="#294C94"
      />
      <path
        d="M23.6286 55.3134C23.6286 53.5421 25.0039 52.0798 26.7736 52.0042C29.3269 51.8952 33.2428 51.7705 37.8 51.7705C42.3572 51.7705 46.2731 51.8952 48.8264 52.0042C50.5961 52.0798 51.9714 53.5421 51.9714 55.3134C51.9714 57.0846 50.5961 58.5469 48.8264 58.6226C46.2731 58.7315 42.3572 58.8562 37.8 58.8562C33.2428 58.8562 29.3269 58.7315 26.7736 58.6226C25.0039 58.5469 23.6286 57.0846 23.6286 55.3134Z"
        fill="#182B54"
      />
    </svg>
  );
};
