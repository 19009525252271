import React from "react";
import { ReactComponent as ExplorerSearchIcon } from "app/assets/vectors/ExplorerSearchIcon.svg";
import { Box } from "@mui/material";
import { colors } from "app/theme";
import { SearchInputProps } from "./data";

export const SearchInput = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > &
    SearchInputProps
) => {
  return (
    <Box
      sx={{
        gap: "4px",
        display: "flex",
        alignItems: "center",
        border: props.showBorder ? "1px solid #A1AEBD" : "none",
        background: colors.secondary.disableButton,
        padding: "4px 8px",
        borderRadius: "5px",
        width: "100%",
        "> input": {
          border: "none",
          width: "255px",
          outline: "none",
          background: "transparent",
          lineHeight: "20px",
          fontSize: "14px",
          "::placeholder": {
            fontSize: colors.secondary.grayText,
          },
        },
      }}
    >
      <ExplorerSearchIcon />
      <input type="text" {...props} />
    </Box>
  );
};
