interface Activity {
  id: string;
  name: string;
  status: string;
  location: string;
}
export const ACTIVITIES: Activity[] = [
  {
    id: "1",
    name: "Nexus Energy Water in Zambia (NEWZA)",
    status: "Pipeline/Identification",
    location: "Zambia",
  },
];

interface ILocation {
  id: string;
  code: string;
  location: string;
  relatedContent: string;
}

export const LOCATIONS: ILocation[] = [
  {
    id: "1",
    code: "SO",
    location: "Somalia",
    relatedContent: "3256 related activities.",
  },
];

interface Organisation {
  id: string;
  iatiId: string;
  organisation: string;
  relatedContent: string;
}

export const ORGANISATIONS: Organisation[] = [
  {
    id: "1",
    iatiId: "XM-DAC-41301",
    organisation: "Food and Agriculture Organization (FAO)",
    relatedContent: "3256 related activities.",
  },
];

interface Sector {
  id: string;
  code: string;
  category: string;
  sectorName: string;
  relatedContent: string;
}

export const SECTORS: Sector[] = [
  {
    id: "1",
    code: "14010",
    category: "140",
    sectorName: "Water sector policy and administrative management",
    relatedContent: "3256 related activities.",
  },
];
