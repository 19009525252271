import React from "react";
import { Box } from "@mui/material";
import { Hero } from "./components/hero";
import { IatiDataInNumbers } from "./components/iati-in-numbers";
import { HearPublishers } from "./components/hear-publishers";

export const PublisherLanding = () => {
  return (
    <React.Fragment>
      <Hero />
      <Box height={50} />
      <IatiDataInNumbers />
      <Box height={50} />
      <HearPublishers />
    </React.Fragment>
  );
};
