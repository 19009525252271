export const FAVOURITES_TABLE_DATA = [
  {
    id: "1",
    title: "ITS Establishement of Nagpur-Mumbai Super Communication Expressway",
    location: "Multiple Countries",
    status: "Pipeline/Identification",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 0,
    locationCode: "",
  },
  {
    id: "2",
    title:
      "Competitiveness Improvement of Agriculture and Allied Sectors Project in the Union Territory of Jammu and Kashmir",
    location: "India",
    status: "Suspended",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 35,
    locationCode: "IN",
  },
  {
    id: "3",
    title:
      "Enabling village communities to become self-custodians of their own development",
    location: "Spain",
    status: "Implementation",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 100,
    locationCode: "ES",
  },
  {
    id: "4",
    title:
      "T004- CREATION OF BUDGETARY COMMITMENT (EN) FOR ADMINISTRATIVE CREDITS 2017-2019 FOR TRUST FUND MADAD",
    location: "Afghanistan",
    status: "Pipeline/Identification",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 0,
    locationCode: "AF",
  },
  {
    id: "5",
    title: `T004 - ACTION DOCUMENT "ENHANCED SUPPORT TO THE PUBLIC HEALTH SYSTEM IN JORDAN: PREVENTION AND MANAGEMENT OF NON-COMMUNICABLE DISEASES (FOR JORDANIANS AND SYRIAN REFUGEES), THROUGH PRIMARY HEALTH CARE" - 22 MEUR`,
    location: "Syria",
    status: "Implementation",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 35,
    locationCode: "SY",
  },
  {
    id: "6",
    title: `CA WITH AECID "ENHANCED SUPPORT TO THE PUBLIC HEALTH SYSTEM IN JORDAN FOR SYRIAN REFUGEES AND JORDANIANS: PREVENTION AND MANAGEMENT OF NON-COMMUNICABLE DISEASES THROUGH PRIMARY HEALTH CARE- 22 MEUR`,
    location: "Syria",
    status: "Finalisation",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 100,
    locationCode: "SY",
  },
  {
    id: "7",
    title:
      "Programme d'appui à la protection et la valorisation du patrimoine culturel en Algérie projets de sites pilotes (PATRIMOINE II)",
    location: "Algeria",
    status: "Closed",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 0,
    locationCode: "DZ",
  },
  {
    id: "8",
    title: "Social policies and inclusion",
    location: "USA",
    status: "Implementation",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 35,
    locationCode: "US",
  },
  {
    id: "9",
    title:
      "EU Support to Fundamental Rights to the Republic of Serbia RS2022AAP",
    location: "Serbia",
    status: "Cancelled",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 0,
    locationCode: "RS",
  },
  {
    id: "10",
    title:
      "EU Support to Fundamental Rights to the Republic of Serbia RS2022AAP",
    location: "Somalia",
    status: "Pipeline/Identification",
    totalCommitment: "USD 435,000",
    commitmentPercentage: 0,
    locationCode: "SO",
  },
];
