import { Box, Divider, Grid, Typography } from "@mui/material";
import { ExplorerLargeCtaIcon } from "app/assets/vectors/jsx/ExplorerLargeCtaIcon";
import { PublisherLargeCtaIcon } from "app/assets/vectors/jsx/PublisherLargeCtaIcon";
import { BarLineChart } from "app/components/charts/bar-line";
import { BarLineChartSampleData } from "app/components/charts/bar-line/data";
import { LineChart } from "app/components/charts/line";
import { LineChartSampleData } from "app/components/charts/line/data";
import { NetworkChart } from "app/components/charts/network";
import { NetworkSampleData } from "app/components/charts/network/data";
import { PieChart } from "app/components/charts/pie";
import { PieChartSampleData } from "app/components/charts/pie/data";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { LandingPageBlockItem } from "app/components/landing-page-block-item";
import { LandingPageWidget } from "app/components/landing-page-widget";
import { colors } from "app/theme";
import React from "react";

export const IatiDataInNumbers = () => {
  const SectorWidgets = () => {
    const data = [
      {
        title: "Disaster prevention and preparedness",
        description: "Disbursement for 2022",
        valueText: "USD 721K",
        percentageChange: 23.01,
      },
      {
        title: "Development Food Assistance",
        description: "Disbursement for 2022",
        valueText: "USD 367K",
        percentageChange: 18.03,
      },
      {
        title: "Energy policy and administrative management",
        description: "Disbursement for 2022",
        valueText: "USD 1,156K",
        percentageChange: 15.03,
      },
      {
        title: "Employment creation",
        description: "Disbursement for 2022",
        valueText: "USD 239K",
        percentageChange: 6.08,
      },
    ];
    return (
      <React.Fragment>
        <Box>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <LandingPageWidget
                title={item.title}
                description={item.description}
                valueText={item.valueText}
                percentageChange={item.percentageChange}
              />
              {index !== data.length - 1 && (
                <Box
                  sx={{
                    padding: "9.5px 0",
                  }}
                >
                  <Divider />
                </Box>
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box height={20} />
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Typography variant="h3" fontSize={"36px"}>
        IATI Data, in Numbers
      </Typography>

      <Box height={20} />

      <Grid container columnSpacing={"30px"}>
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <LineChart
                data={LineChartSampleData}
                yAxisLabelName="% of Success"
                id="iati-in-numbers-line"
                height="300px"
              />
            }
            description={
              <React.Fragment>
                In 2022, available results show activities surpassed about
                <span style={{ color: colors.primary.blue }}> 4.8% </span> of
                their target results on average.
              </React.Fragment>
            }
            linkText="Explore the Latest Data"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <BarLineChart
                data={BarLineChartSampleData}
                yAxisLabelName="USD"
                id="iati-in-numbers-bar-line"
                barWidth="29"
                height="337px"
                xAxisLabelRotate={45}
                showLegend
              />
            }
            title="Funding gaps presented for the year of 2022 by sector."
            description={
              <React.Fragment>
                Funding gaps analysis by sectors shows that
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  Productive Sectors{" "}
                </span>{" "}
                of were the least funded sectors of 2022.
              </React.Fragment>
            }
            linkText="Explore the Latest Data"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <NetworkChart data={NetworkSampleData} height="310px" zoom={3} />
            }
            title="Donor network present for the year 2022, separated by sectors. "
            description={
              <React.Fragment>
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  1528 organisations
                </span>{" "}
                have published data on their development and humanitarian
                resources and activities.
              </React.Fragment>
            }
            linkText="See the Data"
          />
        </Grid>
      </Grid>

      <Box height={50} />
      <Box
        sx={{
          display: "flex",
          columnGap: "20px",
        }}
      >
        <CtaCommonBtn
          variant="large"
          label="Explore Insights"
          text="Unlock the Power of Data for Impactful Fundraising. Discover valuable insights and opportunities to elevate your fundraising efforts like never before."
          link="/"
          bgIcon={<ExplorerLargeCtaIcon />}
        />
        <CtaCommonBtn
          variant="large"
          label="Publish Your Information to IATI"
          text="Join the ranks of NGOs that are already benefiting from AIDA Publication Service. Embrace transparency, engage more donors, and make a difference that truly matters."
          link="/"
          bgIcon={<PublisherLargeCtaIcon />}
          bgColor="green"
        />
      </Box>
      <Box height={50} />
      <Grid container columnSpacing={"30px"} columns={131}>
        <Grid item xs={131} md={40}>
          <LandingPageBlockItem
            content={<SectorWidgets />}
            title="Sectors with significant funding rate changes for 2022."
            description={
              <React.Fragment>
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  Disaster Prevention and Preparedness
                </span>{" "}
                was the sector to have the highest percentage of growth for
                2022.
              </React.Fragment>
            }
            linkText="See the Data"
          />
        </Grid>
        <Grid item xs={131} md={91}>
          <LandingPageBlockItem
            content={
              <React.Fragment>
                <Box height={20} />
                <PieChart
                  id="key-projections-sectors"
                  half
                  donut
                  data={PieChartSampleData}
                  unit="USD"
                  height={"500px"}
                />
              </React.Fragment>
            }
            title="Sectors to which resources were allocated."
            description={
              <React.Fragment>
                Top 10 organisations provides in total of
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  678,2 million development aid{" "}
                </span>{" "}
                in 2022. Which makes up the
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  85% of the total development aid.{" "}
                </span>
              </React.Fragment>
            }
            linkText="See the Data"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
