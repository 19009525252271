import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ImpactChartItemProps } from "app/components/charts/impact/data";

export const ImpactChartItem: React.FC<ImpactChartItemProps> = (
  props: ImpactChartItemProps
) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7} md={8} xl={9}>
        <Box
          sx={{
            gap: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              gap: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" fontSize="12px">
              {props.title}
            </Typography>
            <Box>
              <Typography variant="h6" fontSize="12px">
                Output
              </Typography>
              <Typography variant="h6" fontSize="12px" fontWeight="400">
                {props.output}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" fontSize="12px">
              Indicator
            </Typography>
            <Typography variant="h6" fontSize="12px" fontWeight="400">
              {props.indicator}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5} md={4} xl={3}>
        <Box
          sx={{
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            "> div": {
              gap: "5px",
              display: "flex",
              flexDirection: "row",
              "> h6": {
                minWidth: "50px",
              },
              "> div": {
                width: "100%",
                height: "22px",
                display: "flex",
                fontSize: "12px",
                fontWeight: "700",
                borderRadius: "5px",
                alignItems: "center",
                justifyContent: "center",
                color: colors.primary.white,
              },
            },
          }}
        >
          <Box>
            <Typography variant="h6" fontSize="12px">
              Baseline
            </Typography>
            <Box bgcolor={colors.charts[1]}>0</Box>
          </Box>
          <Box>
            <Typography variant="h6" fontSize="12px">
              Target
            </Typography>
            <Box bgcolor={colors.charts[9]}>25</Box>
          </Box>
          <Box>
            <Typography variant="h6" fontSize="12px">
              Actual
            </Typography>
            <Box bgcolor={colors.charts[7]}>64</Box>
          </Box>
        </Box>
        <Typography fontSize="10px" marginTop="10px">
          <b>Period</b> {props.period}
        </Typography>
      </Grid>
    </Grid>
  );
};
