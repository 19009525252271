import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Table } from "app/components/table";
import Typography from "@mui/material/Typography";
import {
  ConvertedFileActivity,
  GET_CONVERTED_FILE_ACTIVITIES_COLUMNS,
} from "app/components/table/data";

interface PublisherBlockValidateViewProps {
  activities: ConvertedFileActivity[];
  seeTheReportAction: (value: string) => void;
}

export const PublisherBlockValidateView: React.FC<
  PublisherBlockValidateViewProps
> = (props: PublisherBlockValidateViewProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "450px",
        display: "flex",
        padding: "15px 0",
        flexDirection: "column",
        a: {
          color: "inherit",
        },
      }}
    >
      <Box
        sx={{
          gap: "10px",
          width: "100%",
          display: "flex",
          padding: "15px",
          flexDirection: "row",
          alignItems: "flex-start",
          background: colors.secondary.lightGrey,
        }}
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.9987 31.1654C24.8227 31.1654 31.1654 24.8227 31.1654 16.9987C31.1654 9.17466 24.8227 2.83203 16.9987 2.83203C9.17466 2.83203 2.83203 9.17466 2.83203 16.9987C2.83203 24.8227 9.17466 31.1654 16.9987 31.1654Z"
            fill="#66BCB9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.2197 11.015C24.795 11.4604 24.9626 12.3123 24.5968 12.969C21.7588 18.0634 19.1765 21.453 17.6504 23.2674C16.8297 24.2433 15.5099 24.3685 14.5792 23.517C13.2505 22.3013 11.2977 20.2375 9.42894 17.2313C9.07676 16.6648 9.15305 15.9094 9.59193 15.4195L10.7172 14.1636C11.2411 13.5789 12.1022 13.5628 12.6305 14.1427C14.4912 16.1852 15.9343 18.0372 15.9343 18.0372C15.9343 18.0372 18.0548 14.5971 21.3477 10.4261C21.8028 9.84961 22.588 9.75214 23.1574 10.1928L24.2197 11.015Z"
            fill="#004845"
          />
        </svg>
        <Box
          sx={{
            gap: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" fontSize="14px">
            Your data have been converted successfully and Visualised for a
            Preview!
          </Typography>
          <Box>
            <Typography
              fontSize="12px"
              component="div"
              sx={{
                "> span": {
                  fontWeight: "700",
                },
              }}
            >
              Review your pre-visualisations and validity status.{" "}
              <span style={{ color: colors.secondary.red }}>Invalid</span> files
              requires further editing and cannot be published until they are{" "}
              <span style={{ color: colors.primary.green }}>Valid</span>. Your
              publishing status will remain as a{" "}
              <span style={{ color: colors.charts[12] }}>Draft</span> until you
              complete the necessary steps to publish to IATI registry.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Table
        rows={props.activities}
        columns={GET_CONVERTED_FILE_ACTIVITIES_COLUMNS(
          props.seeTheReportAction
        )}
      />
    </Box>
  );
};
