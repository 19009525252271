import React from "react";

export const ExplorerLargeCtaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73"
      height="77"
      viewBox="0 0 73 77"
      fill="none"
      {...props}
    >
      <path
        d="M0.74608 44.6271C0.856607 47.0561 2.56189 49.0676 4.9668 49.426C7.68724 49.8313 11.6749 50.2501 16.5391 50.2501C21.4033 50.2501 25.3909 49.8313 28.1114 49.426C30.5162 49.0676 32.2216 47.0561 32.3321 44.6271C32.5003 40.9319 32.698 34.6489 32.698 25.5364C32.698 16.4239 32.5003 10.1409 32.3321 6.44563C32.2216 4.01669 30.5162 2.0052 28.1114 1.64686C25.3909 1.24149 21.4033 0.822693 16.5391 0.822693C11.6749 0.822693 7.68724 1.24149 4.9668 1.64686C2.56189 2.0052 0.856607 4.01669 0.74608 6.44563C0.577913 10.1409 0.380127 16.4239 0.380127 25.5364C0.380127 34.6489 0.577913 40.9319 0.74608 44.6271Z"
        fill="#294C94"
      />
      <path
        d="M28.6423 76.58C30.4097 76.4912 31.9258 75.4757 32.2366 73.7337C32.4862 72.3346 32.698 70.2854 32.698 67.3595C32.698 64.4336 32.4862 62.3845 32.2366 60.9853C31.9258 59.2433 30.4097 58.2278 28.6423 58.139C26.0757 58.0103 21.8677 57.8542 16.5391 57.8542C11.2104 57.8542 7.00242 58.0103 4.43574 58.139C2.66844 58.2278 1.1523 59.2433 0.841475 60.9853C0.591847 62.3845 0.380127 64.4336 0.380127 67.3595C0.380127 70.2854 0.591847 72.3346 0.841475 73.7337C1.1523 75.4757 2.66844 76.4912 4.43574 76.58C7.00242 76.7087 11.2104 76.8648 16.5391 76.8648C21.8677 76.8648 26.0757 76.7087 28.6423 76.58Z"
        fill="#294C94"
      />
      <path
        d="M72.2542 33.0601C72.1437 30.6311 70.4383 28.6196 68.0335 28.2612C65.3131 27.8559 61.3254 27.4371 56.4612 27.4371C51.597 27.4371 47.6093 27.8559 44.8889 28.2612C42.4841 28.6196 40.7786 30.6311 40.6682 33.0601C40.5 36.7553 40.3022 43.0383 40.3022 52.1508C40.3022 61.2633 40.5 67.5463 40.6682 71.2416C40.7786 73.6705 42.4841 75.682 44.8889 76.0404C47.6093 76.4457 51.597 76.8645 56.4612 76.8645C61.3254 76.8645 65.3131 76.4457 68.0335 76.0404C70.4383 75.682 72.1437 73.6705 72.2542 71.2416C72.4224 67.5463 72.6201 61.2633 72.6201 52.1508C72.6201 43.0383 72.4224 36.7553 72.2542 33.0601Z"
        fill="#294C94"
      />
      <path
        d="M44.358 1.10751C42.5905 1.19623 41.0745 2.21179 40.7636 3.9538C40.514 5.35292 40.3022 7.40203 40.3022 10.328C40.3022 13.2539 40.514 15.303 40.7636 16.7022C41.0745 18.4441 42.5905 19.4597 44.358 19.5484C46.9246 19.6771 51.1325 19.8332 56.4612 19.8332C61.7898 19.8332 65.9978 19.6771 68.5644 19.5484C70.3318 19.4597 71.8479 18.4441 72.1588 16.7022C72.4084 15.303 72.6201 13.2539 72.6201 10.328C72.6201 7.40203 72.4084 5.35292 72.1588 3.95378C71.8479 2.21177 70.3318 1.19623 68.5644 1.10751C65.9978 0.978674 61.7898 0.822693 56.4612 0.822693C51.1325 0.822693 46.9246 0.978674 44.358 1.10751Z"
        fill="#294C94"
      />
    </svg>
  );
};
