import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { NetworkChart } from "app/components/charts/network";
import { NetworkSampleData } from "app/components/charts/network/data";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { ORGANISATIONS } from "app/pages/explorer/pages/organisations/data";
import { EuropeanUnionLogo } from "app/assets/vectors/jsx/EuropeanUnionLogo";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { ORGANISATION_COLUMNS } from "app/pages/explorer/pages/organisations/tableColumns";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerOrganisationsHeaderIcon.svg";

export const ExplorerOrganisations: React.FC = () => {
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Faces Behind Impact: Explore Organizations"
        subtitle="Get ready to dive into the heart of humanitarian aid with AIDA's organisation-specific data. This feature invites you to explore a network of dedicated organizations making a real difference in the world."
        icon={<ExplorerOrganisationsHeaderIcon />}
      />
      <Box height={40} />
      <Typography variant="h2" fontSize={"36px"} lineHeight={"normal"}>
        Explore the Organisation of the Month
      </Typography>
      <Box height={40} />
      <ExploreListBlockItem
        icon={<EuropeanUnionLogo />}
        title="ORGANISATION OF THE MONTH"
        description={
          "European Commission - Humanitarian Aid & Civil Protection"
        }
        leftItems={[
          {
            title: "Overview",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  USD 226 billion
                </span>{" "}
                was spent for
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  218 recipient countries{" "}
                </span>{" "}
                in 2023.
              </React.Fragment>
            ),
          },
          {
            title: "Locations",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  Somalia, Yemen, South Sudan{" "}
                </span>
                and{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  Afghanistan
                </span>{" "}
                were the largest recipient countries of 2022.
              </React.Fragment>
            ),
          },
          {
            title: "Results",
            description: (
              <React.Fragment>
                In 2022, available results show activities surpassed about{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  4.8%
                </span>{" "}
                of their target results on average.
              </React.Fragment>
            ),
          },
          {
            title: "SUSTAINABLE DEVELOPMENT GOALS",
            description: (
              <React.Fragment>
                In 2022,{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  4,678 activities{" "}
                </span>
                were contributing to the SDGs.
              </React.Fragment>
            ),
          },

          {
            title: "Sectors",
            description: (
              <React.Fragment>
                European Commission - Humanitarian Aid & Civil Protection
                provided aid for{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  2 sectors{" "}
                </span>
                in all years.
              </React.Fragment>
            ),
          },
        ]}
        rightContent={{
          title: "NETWORKS",
          description: (
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                123 organisations are in
              </span>{" "}
              collaboration with European Commission - Humanitarian Aid & Civil
              Protection.
            </React.Fragment>
          ),
          content: (
            <NetworkChart height="319px" data={NetworkSampleData} zoom={3} />
          ),
        }}
        buttonText="See the Organisation Page"
        buttonLink="/explorer/organisations/EC"
      />
      <Box height={40} />
      <Typography variant="h2" fontSize={"36px"} lineHeight={"normal"}>
        Explore All Organisations & Search
      </Typography>
      <Box height={40} />
      <ExploreListAllBlock
        columns={ORGANISATION_COLUMNS}
        rows={ORGANISATIONS}
      />
    </Box>
  );
};
