import { Box, Typography } from "@mui/material";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { Table } from "app/components/table";
import { colors } from "app/theme";
import React from "react";
import { LIVE_DATA_UPDATES_TABLE_DATA } from "./data";
import { LIVE_DATA_UPDATES_COLUMNS } from "./tableColumns";

export const LiveDataUpdates = () => {
  return (
    <React.Fragment>
      <Typography variant="h3" fontSize={"36px"}>
        Live Data Updates
      </Typography>
      <Box height={20} />
      <GenericPageBlockItem
        title="RECENT ACTIVITIES"
        description={
          <React.Fragment>
            There's currently{" "}
            <span style={{ color: colors.primary.blue }}>
              1,908 recent activities{" "}
            </span>
            in IATI dataset that have been uploaded in the last 48 hours.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              withSearch
              rows={LIVE_DATA_UPDATES_TABLE_DATA}
              columns={LIVE_DATA_UPDATES_COLUMNS}
              headerFontSize="12px"
            />
          </React.Fragment>
        }
        toolbarShare
      />
    </React.Fragment>
  );
};
