const monthAbbr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDate(date: string) {
  const value = new Date(date);
  return `${value.getDate()} ${monthAbbr[value.getMonth()]} ${value.getFullYear()}`;
}
