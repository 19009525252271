export interface SDGChartProps {
  labelGroup?: string;
  data: { sdg: number; value: number }[];
  expanded?: boolean;
}

export const SDGSampleData = [
  { sdg: 1, value: 1963 },
  { sdg: 2, value: 1852 },
  { sdg: 3, value: 1569 },
  { sdg: 4, value: 1503 },
  { sdg: 5, value: 2305 },
  { sdg: 6, value: 2304 },
  { sdg: 7, value: 632 },
  { sdg: 8, value: 549 },
  { sdg: 9, value: 1053 },
  { sdg: 10, value: 502 },
  { sdg: 11, value: 1032 },
  { sdg: 12, value: 609 },
  { sdg: 13, value: 4509 },
  { sdg: 14, value: 1034 },
  { sdg: 15, value: 1125 },
  { sdg: 16, value: 3459 },
  { sdg: 17, value: 945 },
];

export const SDGSampleData2 = [
  { sdg: 3, value: 3000 },
  { sdg: 13, value: 1300 },
  { sdg: 5, value: 5000 },
  { sdg: 10, value: 1000 },
  { sdg: 1, value: 1000 },
  { sdg: 2, value: 2000 },
];
