import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { BarSeriesOption, BarChart as EChartsBar } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { BarChartProps } from "app/components/charts/bar/data";

echarts.use([EChartsBar, SVGRenderer, GridComponent]);

export const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: "bar-chart",
  });

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });
      const series: BarSeriesOption[] = props.data.series.map((name, id) => {
        return {
          name,
          type: "bar",
          stack: "total",
          barWidth: props.barWidth,
          label: {
            show: false,
          },
          data: props.data.values[id],
        };
      });
      const colors = [
        "#2A1FF9",
        "#0026FF",
        "#0070CC",
        "#0086B3",
        "#009199",
        "#00806C",
        "#004D22",
        "#00330D",
        "#006642",
        "#009658",
        "#00BA2D",
        "#00D40D",
      ];
      const option: echarts.ComposeOption<BarSeriesOption> = {
        grid: {
          top: 40,
          left: 0,
          right: 0,
          bottom: 40,
          containLabel: true,
        },
        color:
          props.data.series.length > 1 ? colors.reverse() : colors.slice(0, 1),
        xAxis: {
          type: "category",
          data: props.data.bars,
          axisLabel: {
            rotate: props.xAxisLabelRotate,
            overflow: "break",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            onZero: false,
          },
        },
        yAxis: {
          type: "value",
          name: props.yAxisLabelName,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
        },
        series,
      };

      chart.setOption(option);
      setStateChart(chart);
    }
  }, [containerRef.current, props.data]);

  return <Box id="bar-chart" ref={containerRef} width="100%" height="450px" />;
};
