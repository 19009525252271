import React from "react";

export const CtaFavouriteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="56"
      viewBox="0 0 54 56"
      fill="none"
      {...props}
    >
      <path
        d="M1.63905 7.13773C1.85708 4.57006 3.49762 2.4569 6.03205 1.95576C9.64672 1.24105 16.1239 0.439941 27 0.439941C37.8761 0.439941 44.3533 1.24105 47.968 1.95578C50.5024 2.45691 52.1429 4.57005 52.361 7.13773C52.7219 11.3872 53.18 19.0343 53.18 30.4666C53.18 41.0365 52.9757 47.5414 52.7325 51.5404C52.5061 55.2632 49.6576 56.4523 46.4935 54.4642L31.1864 44.8466C28.6281 43.2391 25.3719 43.2391 22.8136 44.8466L7.5065 54.4642C4.34242 56.4523 1.49391 55.2632 1.2675 51.5404C1.02432 47.5414 0.820007 41.0365 0.820007 30.4666C0.820007 19.0343 1.27821 11.3872 1.63905 7.13773Z"
        fill="#294C94"
      />
    </svg>
  );
};
