import { Page } from "app/components/page";
import { DataHub } from "app/pages/data-hub";
import { RouteObject } from "react-router-dom";
import { Publisher } from "app/pages/publisher";
import { Explorer } from "app/pages/explorer";
import { Home } from "app/pages/home";
import { ExplorerLocations } from "app/pages/explorer/pages/locations";
import { ExplorerOrganisations } from "app/pages/explorer/pages/organisations";
import { ExplorerActivities } from "app/pages/explorer/pages/activities";
import { ExplorerSectors } from "app/pages/explorer/pages/sectors";
import { ExplorerFavourites } from "app/pages/explorer/pages/favourites";
import ExplorerLocation from "app/pages/explorer/pages/locations/pages/location";

export const ROUTES: RouteObject[] = [
  {
    path: "/",
    element: <Page />,
    errorElement: <div>404</div>,
    children: [
      { path: "/", element: <Home /> },
      { path: "/publisher", element: <Publisher /> },
      { path: "/explorer", element: <Explorer /> },
      { path: "/explorer/locations", element: <ExplorerLocations /> },
      { path: "/explorer/locations/:id", element: <ExplorerLocation /> },
      { path: "/explorer/organisations", element: <ExplorerOrganisations /> },
      { path: "/explorer/activities", element: <ExplorerActivities /> },
      { path: "/explorer/sectors", element: <ExplorerSectors /> },
      { path: "/explorer/favourites", element: <ExplorerFavourites /> },
      { path: "/data-hub", element: <DataHub /> },
      {
        path: "*",
        element: (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 91px - 256px - 150px)",
            }}
          >
            Page not found
          </div>
        ),
      },
    ],
  },
];
