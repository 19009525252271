import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { ExploreListBlockItemProps } from "app/components/explore-list-block-Item/data";
import { Link } from "react-router-dom";

export const ExploreListBlockItem: React.FC<ExploreListBlockItemProps> = (
  props: ExploreListBlockItemProps
) => {
  return (
    <Box
      sx={{
        background: colors.primary.white,
        padding: "20px 30px",
        border: `1px solid ${colors.secondary.iconGray}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            fontSize="12px"
            marginBottom="5px"
            textTransform="uppercase"
            color={colors.text.title}
          >
            {props.title}
          </Typography>
          <Typography
            fontSize="18px"
            component="div"
            fontWeight="700"
            color={colors.text.title}
            lineHeight={"normal"}
            sx={{
              display: "flex",
              columnGap: "6px",
            }}
          >
            {props.icon ?? null} {props.description}
          </Typography>
        </Box>
        {props.buttonText ? (
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "700",
            }}
            variant="contained"
            endIcon={<ArrowForward />}
            component={props.buttonLink ? Link : "button"}
            to={props.buttonLink}
          >
            {props.buttonText}
          </Button>
        ) : null}
      </Box>
      <Box height={20} />
      <Divider />
      <Box height={20} sx={{ borderColor: colors.secondary.iconGray }} />
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderRight: `0.5px solid ${colors.secondary.iconGray}`,
            paddingRight: "20px",
          }}
        >
          {props.leftItems?.map((item, index) => (
            <Box
              key={index}
              sx={{
                paddingBottom: "10px",
                "&:not(:last-child)": {
                  borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
                },
                "&:not(:first-of-type)": {
                  paddingTop: "10px",
                },
              }}
            >
              <Typography
                fontSize="12px"
                marginBottom="5px"
                textTransform="uppercase"
                color={colors.text.title}
              >
                {item.title}
              </Typography>
              <Typography
                fontSize="18px"
                component="div"
                fontWeight="700"
                color={colors.text.title}
                lineHeight={"normal"}
              >
                {item.description}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingLeft: "20px",
          }}
        >
          <Typography
            fontSize="12px"
            marginBottom="5px"
            textTransform="uppercase"
            color={colors.text.title}
          >
            {props.rightContent?.title}
          </Typography>
          <Typography
            fontSize="18px"
            component="div"
            fontWeight="700"
            color={colors.text.title}
            lineHeight={"normal"}
            marginBottom={"10px"}
          >
            {props.rightContent?.description}
          </Typography>
          {props.rightContent?.content}
        </Grid>
      </Grid>
    </Box>
  );
};
