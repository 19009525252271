import { ArrowRight } from "@mui/icons-material";
import { Box, SxProps, TableCellProps, Typography } from "@mui/material";
import { ReactComponent as ListIcon } from "app/assets/vectors/GenericListIcon.svg";
import { CircleFlag } from "react-circle-flags";

export interface GenericTableColumn {
  field: string;
  headerName?: string;
  width?: string | number;
  align?: TableCellProps["align"];
  headerAlign?: TableCellProps["align"];
  renderCell?: (params: { value: any; row: any }) => React.ReactNode;
  headerStyle?: SxProps;
  headerInfo?: string;
}

export interface GenericTableProps {
  id: string;
  columns: GenericTableColumn[];
  rows: any[];
  headerRowStyle?: SxProps;
  rowStyle?: SxProps;
}

export const SAMPLE_LOCATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "code",
    headerName: "Code",
    width: 50,
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    align: "left",
    headerAlign: "left",
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"8px"}>
        <CircleFlag
          width={20}
          height={20}
          countryCode={params.row.code.toLowerCase()}
        />

        <Typography variant="body2" fontSize={"12px"}>
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "relatedContent",
    headerName: "Related Content",
    width: 726,
    renderCell: (params: any) => (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
          <ListIcon />
          <Typography variant="body2" fontSize={"12px"}>
            {params.value}
          </Typography>
        </Box>
        <ArrowRight />
      </Box>
    ),
  },
];

export const SAMPLE_LOCATIONS_DATA = [
  {
    id: "1",
    code: "SO",
    location: "Somalia",
    relatedContent: "3256 related activities.",
  },
  {
    id: "2",
    code: "ZM",
    location: "Zambia",
    relatedContent: "3256 related activities.",
  },
  {
    id: "3",
    code: "ZW",
    location: "Zimbabwe",
    relatedContent: "3256 related activities.",
  },

  {
    id: "1",
    code: "AF",
    location: "Afghanistan",
    relatedContent: "3256 related activities.",
  },
  {
    id: "2",
    code: "AL",
    location: "Albania",
    relatedContent: "3256 related activities.",
  },

  {
    id: "3",
    code: "DZ",
    location: "Algeria",
    relatedContent: "3256 related activities.",
  },
  {
    id: "4",
    code: "AS",
    location: "American Samoa",
    relatedContent: "3256 related activities.",
  },
  {
    id: "5",
    code: "AD",
    location: "Andorra",
    relatedContent: "3256 related activities.",
  },
  {
    id: "6",
    code: "AO",
    location: "Angola",
    relatedContent: "3256 related activities.",
  },
  {
    id: "7",
    code: "AI",
    location: "Anguilla",
    relatedContent: "3256 related activities.",
  },
  {
    id: "8",
    code: "AQ",
    location: "Antarctica",
    relatedContent: "3256 related activities.",
  },
  {
    id: "9",
    code: "AG",
    location: "Antigua and Barbuda",
    relatedContent: "3256 related activities.",
  },
  {
    id: "10",
    code: "AR",
    location: "Argentina",
    relatedContent: "3256 related activities.",
  },
];
