import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  GeomapTooltipLockedIcon,
  GeomapTooltipUnlockedIcon,
} from "app/assets/vectors/jsx/GeomapTooltipLock";
import { formatLocale } from "app/utils/formatLocale";
import { ArrowForward } from "@mui/icons-material";

interface TooltipProps {
  code: string;
  label: string;
  value: number;
  isLocked: boolean;
  lockUnlock: () => void;
}

export const GeomapChartTooltip = React.memo(function TooltipMemoized(
  props: TooltipProps
) {
  return (
    <Box
      sx={{
        left: 20,
        bottom: 20,
        width: "294px",
        padding: "11.5px",
        position: "absolute",
        borderRadius: "14px",
        boxShadow: colors.shadows.main,
        background: colors.primary.white,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{props.label}</Typography>
        {props.isLocked ? (
          <IconButton
            aria-label="close"
            onClick={props.lockUnlock}
            sx={{
              padding: "0",
              "@media (max-width: 768px)": {
                display: "none",
              },
            }}
          >
            <GeomapTooltipLockedIcon variant="publisher" />
          </IconButton>
        ) : (
          <IconButton
            aria-label="close"
            onClick={props.lockUnlock}
            sx={{
              padding: "0",
              "@media (max-width: 768px)": {
                display: "none",
              },
            }}
          >
            <GeomapTooltipUnlockedIcon variant="publisher" />
          </IconButton>
        )}
      </Box>
      <Typography fontSize="13.38px" sx={{ marginTop: "16.72px" }}>
        Incoming Funds
      </Typography>
      <Typography sx={{ marginTop: "5.57px" }} variant="h3" fontWeight={"400"}>
        US$ {formatLocale(props.value)}
      </Typography>

      <Typography fontSize="13.38px" sx={{ marginTop: "5.57px" }}>
        4.576 Activities
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: "16.72px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            fontSize: "12px",
            fontWeight: "700",
            textTransform: "none",
            color: colors.primary.white,
            background: colors.primary.green,
            padding: "6px 10px",
          }}
          variant="contained"
          endIcon={<ArrowForward />}
        >
          Country Page
        </Button>
      </Box>
    </Box>
  );
});
