import React from "react";

export const MinusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="4.58325"
        y="10.0835"
        width="12.8333"
        height="1.83333"
        rx="0.916667"
        fill="white"
      />
    </svg>
  );
};
