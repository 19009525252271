import React from "react";

export const PublisherLargeCtaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="79"
      viewBox="0 0 79 79"
      fill="none"
      {...props}
    >
      <path
        d="M47.9251 69.2718C23.1698 76.5618 7.96005 78.0828 7.96005 78.0828C7.96005 78.0828 5.32794 78.9602 2.69555 76.3279C0.0631632 73.6956 0.94072 71.0635 0.94072 71.0635C0.94072 71.0635 2.46168 55.8538 9.75158 31.0983C10.7884 27.5773 13.6459 24.9051 17.264 24.2863C22.0709 23.4642 29.6582 22.4244 39.5003 21.9322L44.8117 20.1732L58.8503 34.2119L57.0912 39.5231C56.5992 49.3653 55.5592 56.9526 54.7372 61.7595C54.1183 65.3776 51.4461 68.235 47.9251 69.2718Z"
        fill="#00726E"
      />
      <path
        d="M39.5002 21.9318C40.14 14.2544 42.6332 6.49956 43.9597 2.84876C44.4468 1.50798 45.7265 0.65989 47.1405 0.848974C51.2277 1.39554 59.9761 3.45945 67.7697 11.2531C75.5634 19.0467 77.6273 27.7952 78.174 31.8824C78.3631 33.2964 77.5149 34.5761 76.1741 35.0634C72.5233 36.3897 64.7685 38.8829 57.0911 39.5227C57.0911 39.5227 55.4019 34.2222 50.1014 28.9217C44.8007 23.621 39.5002 21.9318 39.5002 21.9318Z"
        fill="#00726E"
      />
      <path
        d="M1.43921 74.6525C5.75227 69.8892 15.4312 59.2211 23.7209 50.2369C23.5705 49.6643 23.4903 49.0632 23.4903 48.4435C23.4903 44.5573 26.6407 41.4071 30.5267 41.4071C34.4129 41.4071 37.563 44.5573 37.563 48.4435C37.563 52.3295 34.4129 55.4798 30.5267 55.4798C29.9069 55.4798 29.3059 55.3996 28.7335 55.2492C19.7384 63.5488 9.05565 73.2408 4.3007 77.5464C3.78105 77.2635 3.23834 76.87 2.69562 76.3273C2.12856 75.7604 1.72438 75.1932 1.43921 74.6525Z"
        fill="#004845"
      />
    </svg>
  );
};
