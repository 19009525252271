import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { LineSeriesOption, LineChart as EChartsLine } from "echarts/charts";
import {
  GridComponent,
  MarkPointComponent,
  MarkPointComponentOption,
} from "echarts/components";
import {
  LineChartProps,
  LineChartSampleData,
} from "app/components/charts/line/data";
import { formatNumber } from "app/utils/formatNumber";

echarts.use([EChartsLine, SVGRenderer, GridComponent, MarkPointComponent]);

export const LineChart: React.FC<LineChartProps> = (props: LineChartProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: `${props.id}-line-chart`,
  });

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });
      const yAxisMin = Math.min(...props.data.map((d) => d.y));

      const yAxisMax = Math.max(...props.data.map((d) => d.y));

      const option: echarts.ComposeOption<LineSeriesOption> = {
        grid: {
          top: 40,
          left: 20,
          right: 0,
          bottom: 20,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: (props.data.length ? props.data : LineChartSampleData).map(
            (d) => d.x
          ),
        },
        yAxis: {
          name: props.yAxisLabelName,
          type: "value",
          splitLine: {
            show: true,
            interval: "auto",
            lineStyle: {
              color: ["#E0E6F1"],
              width: 0.688,
            },
          },
          splitNumber: 3,
        },
        series: [
          {
            data: (props.data.length ? props.data : LineChartSampleData).map(
              (d) => d.y
            ),
            type: "line",
            lineStyle: {
              color: colors.secondary.red,
            },
            symbol: "circle",
            itemStyle: {
              color: colors.secondary.red,
            },
          },
        ],
      };

      const signalLineOption: echarts.ComposeOption<
        LineSeriesOption | MarkPointComponentOption
      > = {
        grid: {
          top: 40,
          left: 20,
          right: 20,
          bottom: 20,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: (props.data.length ? props.data : LineChartSampleData).map(
            (d) => d.x
          ),
          min: 0,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors.secondary.lightGrayText,
            },
          },
          axisTick: {
            show: false,
          },
          boundaryGap: false,
          axisLabel: {
            formatter: function (value: any, index: number) {
              // Show the label only for the first and last categories
              if (index === 0 || index === props.data.length - 1) {
                return value;
              }
              return "";
            },
          },
        },
        yAxis: {
          name: props.yAxisLabelName,
          type: "value",
          splitLine: {
            show: false,
          },
          min: "dataMin",
          axisLine: {
            show: true,
            lineStyle: {
              color: colors.secondary.lightGrayText,
            },
          },
          axisLabel: {
            formatter: function (value: any, index: number) {
              // Get the min and max values of the y-axis
              const minValue = yAxisMin;
              const maxValue = yAxisMax;

              // Show the label only for the min and max values
              if (value === minValue || value === maxValue) {
                return formatNumber(value, true);
              }
              return "";
            },
          },
        },
        series: [
          {
            data: (props.data.length ? props.data : LineChartSampleData).map(
              (d) => d.y
            ),
            type: "line",
            lineStyle: {
              color: colors.secondary.blue,
              width: 3,
            },
            symbol: "circle",
            symbolSize: (value: any, params: any) => {
              // Show the symbol only for the min and max values
              if (
                params.dataIndex === 0 ||
                params.dataIndex === props.data.length - 1
              ) {
                return 20;
              }
              return 0;
            },
            itemStyle: {
              color: colors.secondary.blue,
            },
            markPoint: {
              data: (props.data.length ? props.data : LineChartSampleData)
                .map((d) => d.y)
                .map((value, index) => ({
                  name: "{b}",
                  coord: [index, yAxisMin], // Position based on the data
                  label: {
                    show: index === 0 || index === props.data.length - 1,
                    position: "bottom",
                    formatter: () => formatNumber(value, true), // Convert to 'K' format
                    color: "#1976d2", // Color to match your design
                    fontWeight: "bold",
                    fontSize: "12px",
                    offset: [0, 20],
                  },
                  symbolSize: 0, // Hide the default markPoint symbol
                })),
            },
          },
        ],
      };

      chart.setOption(
        props.variant === "signalLine" ? signalLineOption : option
      );
      setStateChart(chart);
    }
  }, [
    containerRef.current,
    props.data,
    props.yAxisLabelName,
    props.variant,
    props.width,
    props.height,
  ]);

  return (
    <Box
      id={`${props.id}-line-chart`}
      ref={containerRef}
      width={props?.width ?? "100%"}
      height={props?.height ?? "450px"}
    />
  );
};
