export interface HeaderButtonProps {
  label: string;
  link?: string;
  expandable?: boolean;
}

export interface HeaderButtonsProps {
  activeButton: string;
  buttons: HeaderButtonProps[];
  handleButtonClick: (label: string) => void;
}

export const headerButtons: HeaderButtonProps[] = [
  {
    label: "Product",
    expandable: true,
  },
  {
    label: "Plans",
    expandable: true,
  },
  {
    label: "Resources",
    expandable: true,
  },
  {
    label: "Sign In",
    expandable: true,
  },
];
