import { Box, Grid, SelectChangeEvent } from "@mui/material";
import { DownloadButton } from "app/components/download-button";
import { SAMPLE_LOCATIONS_DATA } from "app/components/generic-table/data";
import { PageHeader } from "app/components/page-header";
import { SelectField } from "app/components/select-field";
import { ShareButton } from "app/components/share-button";
import { YearSelectButton } from "app/components/year-select";
import TransactionTypeSelect from "app/pages/explorer/components/transaction-type";
import React from "react";
import { CircleFlag } from "react-circle-flags";
import { useNavigate, useParams } from "react-router-dom";
import { InsightsBlock } from "./components/insights-block";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { colors } from "app/theme";
import { Table } from "app/components/table";
import { LIVE_DATA_UPDATES_TABLE_DATA } from "app/pages/home/explorer/components/live-data-updates/data";
import { LIVE_DATA_UPDATES_COLUMNS } from "app/pages/home/explorer/components/live-data-updates/tableColumns";

const ExplorerLocation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const location = SAMPLE_LOCATIONS_DATA.find(
    (location) => location.code.toLowerCase() === id?.toLowerCase()
  );

  const handleLocationChange = (e: SelectChangeEvent) => {
    navigate(`/explorer/locations/${e.target.value}`);
  };

  const [expandedBlock, setExpandedBlock] = React.useState<
    | "locations"
    | "results"
    | "sdgs"
    | "networks"
    | "sectors"
    | "impact"
    | "budgetLines"
    | "organisations"
    | "key-locations"
    | "key-organisations"
    | "key-sectors"
    | "key-targets"
    | "geospatial-data"
    | null
  >(null);

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title={location?.location!}
        subtitle={`Showcasing country-specific data of ${location?.location}.`}
        icon={
          <Box
            sx={{
              marginRight: "20px",
            }}
          >
            <CircleFlag
              countryCode={id?.toLowerCase()!}
              width={60}
              height={60}
            />
          </Box>
        }
      />
      <Box height={30} />
      <Grid
        justifyContent={"space-between"}
        alignItems={"center"}
        display={"flex"}
      >
        <SelectField
          id="location-select"
          options={SAMPLE_LOCATIONS_DATA.map((s) => ({
            label: (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <CircleFlag
                  width={12}
                  height={12}
                  countryCode={s.code.toLowerCase()}
                />
                {s.location}
              </Box>
            ),
            value: s.code,
          }))}
          placeholder="Select Another Location"
          value={""}
          onChange={handleLocationChange}
          variant="outlined"
          width="300px"
        />
        <Grid display={"flex"} alignItems={"center"} columnGap={"8px"}>
          <TransactionTypeSelect />
          <YearSelectButton />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={30} />
      <InsightsBlock
        expandedBlock={expandedBlock}
        setExpandedBlock={handleSetExpandedBlock}
      />
      <Box height={30} />

      <GenericPageBlockItem
        title="RECENT ACTIVITIES"
        description={
          <React.Fragment>
            Currently{" "}
            <span style={{ color: colors.primary.blue }}>
              1,908 active activities{" "}
            </span>
            taking place in {location?.location} in 2022.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              withSearch
              rows={LIVE_DATA_UPDATES_TABLE_DATA}
              columns={LIVE_DATA_UPDATES_COLUMNS}
              headerFontSize="12px"
            />
          </React.Fragment>
        }
        toolbarShare
      />
    </Box>
  );
};

export default ExplorerLocation;
