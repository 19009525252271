import { colors } from "app/theme";
import React from "react";

export const GeomapTooltipLockedIcon = (props: {
  variant: "publisher" | "explorer";
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26306 8.85847C6.44831 5.70911 9.05633 3.25 12.2111 3.25H13.7893C16.9441 3.25 19.5521 5.70911 19.7373 8.85847L20.039 13.9879C20.0918 14.8838 19.4082 15.6528 18.5123 15.7055C17.6164 15.7582 16.8474 15.0747 16.7947 14.1788L16.4929 9.0493C16.4087 7.61778 15.2233 6.5 13.7893 6.5H12.2111C10.7771 6.5 9.59165 7.61778 9.50748 9.0493L9.20572 14.1788C9.15301 15.0747 8.38401 15.7582 7.48809 15.7055C6.59218 15.6528 5.90865 14.8838 5.96135 13.9879L6.26306 8.85847Z"
        fill={
          props.variant === "explorer"
            ? colors.shades.blue[400]
            : colors.shades.green[400]
        }
      />
      <path
        d="M3.44473 12.8325C3.55068 11.8946 4.23989 11.2431 5.178 11.1392C6.49643 10.9932 8.88046 10.8333 13 10.8333C17.2332 10.8333 19.6097 11.0021 20.8833 11.1512C21.7394 11.2515 22.3817 11.8317 22.5027 12.6851C22.6298 13.5819 22.75 15.0369 22.75 17.3333C22.75 19.6297 22.6298 21.0848 22.5027 21.9816C22.3817 22.835 21.7394 23.4152 20.8833 23.5154C19.6097 23.6646 17.2332 23.8333 13 23.8333C8.76677 23.8333 6.39026 23.6646 5.11669 23.5154C4.26059 23.4152 3.61834 22.835 3.49735 21.9816C3.3702 21.0848 3.25 19.6297 3.25 17.3333C3.25 15.1587 3.34239 13.7386 3.44473 12.8325Z"
        fill={
          props.variant === "explorer"
            ? colors.shades.blue[200]
            : colors.shades.green[200]
        }
      />
      <path
        d="M12.9998 14.625C13.547 14.625 14.0034 15.0388 14.034 15.5852C14.06 16.0499 14.0832 16.6544 14.0832 17.3333C14.0832 18.0123 14.06 18.6168 14.034 19.0815C14.0034 19.6278 13.547 20.0417 12.9998 20.0417C12.4526 20.0417 11.9962 19.6278 11.9657 19.0815C11.9397 18.6168 11.9165 18.0123 11.9165 17.3333C11.9165 16.6544 11.9397 16.0499 11.9657 15.5852C11.9962 15.0388 12.4526 14.625 12.9998 14.625Z"
        fill={
          props.variant === "explorer"
            ? colors.shades.blue[400]
            : colors.shades.green[400]
        }
      />
    </svg>
  );
};

export const GeomapTooltipUnlockedIcon = (props: {
  variant: "publisher" | "explorer";
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26306 7.77513C6.44831 4.62575 9.05633 2.16666 12.2111 2.16666H13.804C16.9505 2.16666 19.5517 4.61931 19.7365 7.7604C19.7892 8.65631 19.1056 9.42532 18.2097 9.47802C17.3138 9.53073 16.5448 8.8472 16.4921 7.95128C16.4084 6.528 15.2297 5.41666 13.804 5.41666H12.2111C10.7771 5.41666 9.59166 6.53444 9.50748 7.96596L9.20572 13.0954C9.15301 13.9914 8.38401 14.6749 7.48809 14.6222C6.59218 14.5695 5.90865 13.8005 5.96135 12.9046L6.26306 7.77513Z"
        fill={
          props.variant === "explorer"
            ? colors.shades.blue[400]
            : colors.shades.green[400]
        }
      />
      <path
        d="M3.44473 12.8325C3.55068 11.8946 4.23989 11.2431 5.178 11.1392C6.49643 10.9932 8.88046 10.8333 13 10.8333C17.2332 10.8333 19.6097 11.0021 20.8833 11.1512C21.7394 11.2515 22.3817 11.8317 22.5027 12.6851C22.6298 13.5819 22.75 15.0369 22.75 17.3333C22.75 19.6297 22.6298 21.0848 22.5027 21.9816C22.3817 22.835 21.7394 23.4152 20.8833 23.5154C19.6097 23.6646 17.2332 23.8333 13 23.8333C8.76677 23.8333 6.39026 23.6646 5.11669 23.5154C4.26059 23.4152 3.61834 22.835 3.49735 21.9816C3.3702 21.0848 3.25 19.6297 3.25 17.3333C3.25 15.1587 3.34239 13.7386 3.44473 12.8325Z"
        fill={
          props.variant === "explorer"
            ? colors.shades.blue[200]
            : colors.shades.green[200]
        }
      />
      <path
        d="M10.2915 17.3333C10.2915 16.7861 10.7053 16.3297 11.2517 16.2992C11.7164 16.2732 12.3209 16.25 12.9998 16.25C13.6788 16.25 14.2833 16.2732 14.748 16.2992C15.2943 16.3297 15.7082 16.7861 15.7082 17.3333C15.7082 17.8805 15.2943 18.3369 14.748 18.3675C14.2833 18.3935 13.6788 18.4167 12.9998 18.4167C12.3209 18.4167 11.7164 18.3935 11.2517 18.3675C10.7053 18.3369 10.2915 17.8805 10.2915 17.3333Z"
        fill={
          props.variant === "explorer"
            ? colors.shades.blue[400]
            : colors.shades.green[400]
        }
      />
    </svg>
  );
};
