import React from "react";

export const ResetIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9937 19.1875H11.9858C10.2368 19.1859 8.55092 18.547 7.2396 17.3906L6.40984 16.653V18.3889C6.40984 18.8305 6.05207 19.1875 5.61123 19.1875C5.1704 19.1875 4.81262 18.8305 4.81262 18.3889V14.3958C4.81262 13.9542 5.1704 13.5972 5.61123 13.5972H9.60429C10.0451 13.5972 10.4029 13.9542 10.4029 14.3958C10.4029 14.8375 10.0451 15.1944 9.60429 15.1944H7.17282L8.29856 16.1951C9.31599 17.0927 10.6273 17.5887 11.9873 17.5903H11.9937C15.0732 17.5903 17.5808 15.0866 17.584 12.0064C17.5872 8.92375 15.082 6.41372 12.0001 6.40972H11.9554C9.13631 6.40972 6.75644 8.50528 6.40426 11.3012C6.34915 11.7389 5.94506 12.0391 5.51221 11.9936C5.07457 11.9385 4.76471 11.5392 4.81981 11.1016C5.27183 7.50701 8.3321 4.8125 11.9498 4.8125H12.0065C15.9644 4.81649 19.1852 8.04448 19.1804 12.008C19.1756 15.9683 15.9525 19.1875 11.9937 19.1875Z"
        fill="#454545"
      />
    </svg>
  );
};
