import React from "react";

export const CtaSectorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="72"
      viewBox="0 0 69 72"
      fill="none"
      {...props}
    >
      <path
        d="M13.7109 47.584C13.8043 46.7221 14.5399 46.2853 15.4164 46.2853H31.0552C31.6332 53.7772 32.1112 59.6382 32.1112 59.6382H37.2574C37.2574 59.6382 37.7251 53.7772 38.2936 46.2853H53.9549C54.8316 46.2853 55.5672 46.7221 55.6605 47.584L57.8434 59.9996L63.8285 59.2768L62.4831 46.8611C62.0162 42.5516 58.3382 39.5005 53.9549 39.5005H38.8065C39.1723 34.6393 39.5357 29.5398 39.8121 25.7139H29.5195C29.7958 29.5398 30.1632 34.6393 30.5342 39.5005H15.4164C11.0332 39.5005 7.35511 42.5516 6.88814 46.8611L5.54282 59.2768L11.5267 59.9996L13.7109 47.584Z"
        fill="#294C94"
      />
      <path
        d="M19.4879 24.6903C19.6674 27.6444 21.5894 29.9323 24.5241 30.3152C26.7895 30.611 30.0636 30.8571 34.6857 30.8571C39.3077 30.8571 42.5819 30.611 44.8473 30.3152C47.782 29.9323 49.704 27.6444 49.8835 24.6903C50.0119 22.5775 50.1143 19.5885 50.1143 15.4286C50.1143 11.2688 50.0119 8.27962 49.8835 6.16683C49.704 3.21276 47.782 0.924857 44.8473 0.541851C42.5819 0.246172 39.3077 0 34.6857 0C30.0636 0 26.7895 0.246172 24.5241 0.541851C21.5894 0.924857 19.6674 3.21276 19.4879 6.16683C19.3595 8.27962 19.2571 11.2688 19.2571 15.4286C19.2571 19.5885 19.3595 22.5775 19.4879 24.6903Z"
        fill="#294C94"
      />
      <path
        d="M51.8681 66.5079C51.9513 69.4662 53.8627 71.7025 56.8159 71.8947C57.8056 71.959 58.9898 72.0003 60.4 72.0003C61.8101 72.0003 62.9944 71.959 63.984 71.8947C66.9372 71.7025 68.8486 69.4662 68.9318 66.5079C68.9563 65.6396 68.9714 64.6208 68.9714 63.4288C68.9714 62.2369 68.9563 61.2181 68.9318 60.3498C68.8486 57.3915 66.9372 55.1552 63.984 54.963C62.9944 54.8987 61.8101 54.8574 60.4 54.8574C58.9898 54.8574 57.8056 54.8987 56.8159 54.963C53.8627 55.1552 51.9513 57.3915 51.8681 60.3498C51.8436 61.2181 51.8285 62.2369 51.8285 63.4288C51.8285 64.6208 51.8436 65.6396 51.8681 66.5079Z"
        fill="#294C94"
      />
      <path
        d="M26.1539 66.5079C26.237 69.4662 28.1485 71.7025 31.1017 71.8947C32.0913 71.959 33.2755 72.0003 34.6857 72.0003C36.0959 72.0003 37.2801 71.959 38.2698 71.8947C41.223 71.7025 43.1344 69.4662 43.2175 66.5079C43.2421 65.6396 43.2571 64.6208 43.2571 63.4288C43.2571 62.2369 43.2421 61.2181 43.2175 60.3498C43.1344 57.3915 41.223 55.1552 38.2698 54.963C37.2801 54.8987 36.0959 54.8574 34.6857 54.8574C33.2755 54.8574 32.0913 54.8987 31.1017 54.963C28.1485 55.1552 26.237 57.3915 26.1539 60.3498C26.1294 61.2181 26.1143 62.2369 26.1143 63.4288C26.1143 64.6208 26.1294 65.6396 26.1539 66.5079Z"
        fill="#294C94"
      />
      <path
        d="M0.439575 66.5079C0.52282 69.4662 2.43413 71.7025 5.38738 71.8947C6.37704 71.959 7.56132 72.0003 8.97143 72.0003C10.3816 72.0003 11.5658 71.959 12.5555 71.8947C15.5087 71.7025 17.4201 69.4662 17.5033 66.5079C17.5278 65.6396 17.5429 64.6208 17.5429 63.4288C17.5429 62.2369 17.5278 61.2181 17.5033 60.3498C17.4201 57.3915 15.5087 55.1552 12.5555 54.963C11.5658 54.8987 10.3816 54.8574 8.97143 54.8574C7.56132 54.8574 6.37705 54.8987 5.3874 54.963C2.43413 55.1552 0.52282 57.3915 0.439575 60.3498C0.415146 61.2181 0.400009 62.2369 0.400009 63.4288C0.400009 64.6208 0.415146 65.6396 0.439575 66.5079Z"
        fill="#294C94"
      />
    </svg>
  );
};
