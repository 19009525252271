import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ReactComponent as PremiumIcon } from "app/assets/vectors/HeaderPlansViewPremium.svg";
import { ReactComponent as StandardIcon } from "app/assets/vectors/HeaderPlansViewStandard.svg";
import { HeaderViewCommonBlockItem } from "app/components/header/sub-components/expanded-view/data";
import { ReactComponent as FreePublisherIcon } from "app/assets/vectors/HeaderPlansViewFreePublisher.svg";

const Plans = [
  {
    title: "Free Publisher Plan",
    subtitle:
      "The perfect package to get you started with publishing your IATI data.",
    icon: FreePublisherIcon,
  },
  {
    title: "Standard Plan",
    subtitle: "The perfect package to get you started in your IATI journey.",
    icon: StandardIcon,
  },
  {
    title: "Premium Plan",
    subtitle: "A step-by-step guide throughout your IATI journey.",
    icon: PremiumIcon,
  },
];

export const HeaderPlansView: React.FC = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        "> div": {
          paddingTop: "0px !important",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h5" fontSize="14px" marginBottom="24px">
          Plans
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        {Plans.map((item) => (
          <Grid item xs={12} sm={4} md={3} key={item.title}>
            <HeaderViewCommonBlockItem
              title={item.title}
              icon={<item.icon />}
              subtitle={item.subtitle}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
