import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { MapSeriesOption, MapChart as EChartsMap } from "echarts/charts";
import {
  VisualMapComponent,
  VisualMapComponentOption,
} from "echarts/components";
import { GeomapChartProps } from "app/components/charts/geomap/data";
import { useSessionStorage } from "react-use";
import { GeomapChartTooltip } from "./tooltip";
import { Typography } from "@mui/material";
import { formatLocale } from "app/utils/formatLocale";

echarts.use([EChartsMap, CanvasRenderer, VisualMapComponent]);

export const GeomapChart: React.FC<GeomapChartProps> = (
  props: GeomapChartProps
) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [geoJson, setGeoJson] = React.useState<any>(null);
  const [isLocked, setIsLocked] = useSessionStorage<boolean>(
    "geomap-is-locked",
    false
  );
  const [tooltip, setTooltip] = useSessionStorage<{
    code: string;
    label: string;
    value: number;
  } | null>("geomap-tooltip", null);

  function calculateCentroid(coordinates: [number, number][]) {
    let x = 0,
      y = 0;
    let signedArea = 0;

    for (let i = 0; i < coordinates.length - 1; i++) {
      const [x0, y0] = coordinates[i];
      const [x1, y1] = coordinates[i + 1];

      const a = x0 * y1 - x1 * y0;
      signedArea += a;

      x += (x0 + x1) * a;
      y += (y0 + y1) * a;
    }

    signedArea *= 0.5;
    x /= 6 * signedArea;
    y /= 6 * signedArea;

    return [x, y];
  }

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: "geomap-chart",
  });

  const mapColors = [
    colors.shades.blue[100],
    colors.shades.blue[200],
    colors.shades.blue[400],
    colors.shades.blue[500],
    colors.shades.blue[600],
  ];

  React.useEffect(() => {
    if (!geoJson) {
      fetch("/static/world.geo.json")
        .then((res) => res.json())
        .then((data) => {
          setGeoJson({
            ...data,
            features: data.features.filter(
              (feature: any) => feature.id !== "ATA"
            ),
          });
        })
        .catch((err) => console.log(err));
    }
  }, []);

  React.useEffect(() => {
    if (containerRef.current && geoJson) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "canvas",
      });

      echarts.registerMap("World", geoJson);

      const getCenter = (name: string) => {
        const feature = geoJson.features.find(
          (f: any) => f.properties.name === name
        );

        if (feature) {
          const centroid = calculateCentroid(feature.geometry.coordinates[0]);
          return centroid;
        }
        return [0, 0];
      };

      const sizes = props.data.map((d) => d.value);

      const option: echarts.ComposeOption<
        MapSeriesOption | VisualMapComponentOption
      > = {
        visualMap: {
          left: "right",
          min: Math.min(...sizes),
          max: Math.max(...sizes),
          inRange: {
            color: mapColors,
          },
          text: ["High", "Low"],
          calculable: true,
          show: false,
        },
        series: [
          {
            type: "map",
            roam: false,
            map: "World",
            data: props.data,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            center: props.focusCountry
              ? getCenter(props.focusCountry)
              : undefined,
            zoom: props.zoom ?? 1,
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                areaColor: "#cdd4df",
              },
            },
            select: {
              disabled: true,
            },
          },
        ],
      };

      chart.setOption(option);

      chart.on("click", (params: any) => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        const gtooltip = JSON.parse(
          sessionStorage.getItem("geomap-tooltip")?.toString() ?? "{code: ''}"
        );

        if (params.data) {
          if (
            params.data.iso2 === gtooltip?.code &&
            locked &&
            locked === "true"
          ) {
            setIsLocked(false);
          } else {
            setTooltip({
              code: params.data?.iso2 ?? params.data?.name,
              label: params.data?.full_name ?? params.data?.name,
              value: params.data.value,
            });
            setIsLocked(true);
          }
        } else {
          setIsLocked(false);
          if (tooltip) {
            setTooltip(null);
          }
        }
      });
      chart.on("mouseover", (params: any) => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        if (params.data && (!locked || locked === "false")) {
          setTooltip({
            code: params.data?.iso2 ?? params.data?.name,
            label: params.data?.full_name ?? params.data?.name,
            value: params.data.value,
          });
        }
      });
      chart.on("mouseout", () => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        if (!locked || locked === "false") {
          setTooltip(null);
        }
      });

      setStateChart(chart);
    }
  }, [
    containerRef.current,
    props.data,
    geoJson,
    props.focusCountry,
    props.zoom,
  ]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        id="geomap-chart"
        ref={containerRef}
        width="100%"
        height={props.height ?? "450px"}
      />
      {props.showLegend && (
        <Box
          sx={{
            right: 20,
            bottom: 20,
            width: "350px",
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            "@media (max-width: 768px)": {
              width: "80%",
            },
          }}
        >
          <Typography fontSize="12px">Total commitment in US$</Typography>
          <Box
            sx={{
              width: "100%",
              height: "14px",
              display: "flex",
              padding: "0 7px",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "14px",
              justifyContent: "space-between",
              border: `1px solid ${colors.primary.blue}`,
              background: `linear-gradient(90deg, ${mapColors[0]} 5.78%, ${
                mapColors[mapColors.length - 1]
              } 93.75%)`,
            }}
          >
            <Typography fontSize="12px">0</Typography>
            <Typography fontSize="12px" color={colors.primary.white}>
              {formatLocale(Math.max(...props.data.map((d) => d.value)))}
            </Typography>
          </Box>
        </Box>
      )}
      {props.showTooltip && tooltip && (
        <GeomapChartTooltip
          isLocked={isLocked}
          code={tooltip.code}
          label={tooltip.label}
          value={tooltip.value}
          lockUnlock={() => {
            setTooltip(null);
            setIsLocked(!isLocked);
          }}
        />
      )}
    </Box>
  );
};
