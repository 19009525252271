import { Box, Divider, Typography } from "@mui/material";
import { colors } from "app/theme";
import { ReactComponent as ExplorerSearchIcon } from "app/assets/vectors/ExplorerSearchIcon.svg";
import React from "react";
import { ExploreListAllBlockProps } from "./data";
import { GenericTable } from "../generic-table";

export const ExploreListAllBlock: React.FC<ExploreListAllBlockProps> = (
  props: ExploreListAllBlockProps
) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [activeOption, setActiveOption] = React.useState("A");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const options = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "All",
  ];

  const filteredRows = React.useMemo(() => {
    let temp = props.rows;
    if (activeOption !== "All") {
      temp = temp.filter((row) => {
        return row.name[0].toLowerCase() === activeOption.toLowerCase();
      });
    }
    if (searchValue === "") {
      return temp;
    } else {
      temp = temp.filter((row) => {
        return row.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    return temp;
  }, [activeOption, searchValue, props.rows]);

  return (
    <Box
      sx={{
        background: colors.primary.white,
        padding: "30px",
      }}
    >
      <Box
        sx={{
          gap: "4px",
          display: "flex",
          alignItems: "center",
          background: colors.secondary.disableButton,
          padding: "4px 8px",
          borderRadius: "5px",
          width: "100%",
          "> input": {
            border: "none",
            width: "calc(100% - 20px)",
            outline: "none",
            background: "transparent",
            lineHeight: "20px",
            fontSize: "14px",
          },
        }}
      >
        <ExplorerSearchIcon />
        <input
          type="text"
          placeholder="Quick Search"
          onChange={handleChange}
          value={searchValue}
        />
      </Box>
      {props.extraOptions ? (
        <React.Fragment>
          {" "}
          <Box
            sx={{
              margin: "20px 10px",
              display: "flex",
              columnGap: "30px",
            }}
          >
            {props.extraOptions.map((option) => (
              <Typography
                fontSize={"18px"}
                lineHeight={"24px"}
                color={colors.text.title}
                padding={"0"}
                component="button"
                key={option}
                sx={{
                  textDecoration:
                    props.activeExtraOption === option ? "underline" : "none",
                  cursor: "pointer",
                  border: "none",
                  background: "transparent",
                }}
                onClick={() => props.setActiveExtraOption?.(option)}
              >
                {option}
              </Typography>
            ))}
          </Box>
          <Divider />
        </React.Fragment>
      ) : null}
      <Box
        sx={{
          margin: "20px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {options.map((option) => (
          <Typography
            fontSize={"18px"}
            lineHeight={"24px"}
            color={colors.text.title}
            padding={"0"}
            component="button"
            key={option}
            sx={{
              textDecoration: activeOption === option ? "underline" : "none",
              cursor: "pointer",
              border: "none",
              background: "transparent",
            }}
            onClick={() => setActiveOption(option)}
          >
            {option}
          </Typography>
        ))}
      </Box>
      <Divider />
      <Typography
        variant="h5"
        textTransform={"uppercase"}
        sx={{
          margin: "10px",
        }}
      >
        {activeOption}
      </Typography>
      <GenericTable
        id="explorer-table"
        headerRowStyle={{
          borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
        }}
        rowStyle={{
          borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
          cursor: "pointer",
          ":hover": {
            background: colors.secondary.lightGrey,
          },
        }}
        columns={props.columns}
        rows={filteredRows}
      />
    </Box>
  );
};
