export function formatNumber(number: number, abr: boolean = false): string {
  const suffixes = ["", "thousand", "million", "billion", "trillion"];
  const suffixesAbr = ["", "K", "M", "B", "T"];
  let tier = Math.floor(Math.log10(number) / 3);

  if (tier === 0) return number.toString(); // if the number is less than 1000, return it as is

  const suffix = abr ? suffixesAbr[tier] : suffixes[tier];
  const scaled = number / Math.pow(10, tier * 3);

  // To handle decimals and round to a certain decimal place
  const formatted = scaled.toFixed(1).replace(/\.0$/, "");

  return `${formatted}${abr ? "" : " "}${suffix}`;
}
