import React from "react";

export const UpDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      {...props}
    >
      <path
        d="M11.5555 15.7936C12.5832 15.1026 14.0494 13.9469 14.9337 12.4992C15.2146 12.0394 14.9172 11.4884 14.3764 11.4147C13.6207 11.3118 12.8869 11.2603 12.8869 11.2603L12.6819 5.92495C12.6672 5.54357 12.5598 5.14008 12.196 5.00573C12.0304 4.94463 11.811 4.8999 11.5233 4.8999C11.2046 4.8999 10.9697 4.95474 10.799 5.026C10.4743 5.16149 10.373 5.5211 10.3511 5.86689C10.2633 7.24995 10.0609 11.1823 10.2911 15.1561C10.3282 15.7954 11.0174 16.1554 11.5555 15.7936Z"
        fill="#B6B6B6"
      />
      <path
        d="M7.04837 4.60246C6.02067 5.29346 4.55452 6.44915 3.67023 7.89681C3.38934 8.35664 3.68665 8.90764 4.2275 8.98132C4.98318 9.08424 5.71696 9.13572 5.71696 9.13572L5.922 14.4711C5.93666 14.8525 6.04412 15.256 6.40792 15.3903C6.57349 15.4514 6.79291 15.4962 7.08064 15.4962C7.39932 15.4962 7.6342 15.4413 7.80489 15.3701C8.12962 15.2346 8.2309 14.875 8.25284 14.5292C8.34057 13.1461 8.543 9.21373 8.31276 5.24C8.27571 4.60061 7.58652 4.2406 7.04837 4.60246Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};
