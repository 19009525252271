import React from "react";

export const ArrowDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00006 11.1666C7.86735 11.1672 7.73998 11.1143 7.64673 11.0199L2.31339 5.68656C2.12982 5.48956 2.13524 5.18256 2.32565 4.99215C2.51605 4.80174 2.82305 4.79633 3.02006 4.9799L8.00006 9.9599L12.9801 4.9799C13.1771 4.79633 13.4841 4.80174 13.6745 4.99215C13.8649 5.18256 13.8703 5.48956 13.6867 5.68656L8.35339 11.0199C8.26014 11.1143 8.13277 11.1672 8.00006 11.1666Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowRise = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9378 8.30957L19.3325 6.16553L17.4919 13.6415L15.1958 11.4373L11.4921 15.2954C11.3664 15.4263 11.1927 15.5004 11.0111 15.5004C10.8296 15.5004 10.6558 15.4263 10.5301 15.2954L7.81059 12.4625L3.81087 16.6289C3.55584 16.8946 3.13375 16.9032 2.8681 16.6482C2.60245 16.3931 2.59383 15.971 2.84886 15.7054L7.32959 11.038C7.45532 10.907 7.62903 10.833 7.81059 10.833C7.99215 10.833 8.16586 10.907 8.29159 11.038L11.0111 13.8708L14.2338 10.5138L11.9378 8.30957Z"
        fill="#2EA390"
      />
    </svg>
  );
};

export const ArrowFall = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.52 12.1062L16.6641 19.5009L9.18804 17.6604L11.3923 15.3643L7.53422 11.6606C7.40325 11.5348 7.32921 11.3611 7.32921 11.1796C7.32921 10.998 7.40325 10.8243 7.53422 10.6986L10.3671 7.97905L6.20067 3.97932C5.93502 3.7243 5.92641 3.30221 6.18143 3.03656C6.43646 2.77091 6.85855 2.76229 7.1242 3.01732L11.7916 7.49804C11.9226 7.62378 11.9966 7.79749 11.9966 7.97905C11.9966 8.16061 11.9226 8.33431 11.7916 8.46005L8.95879 11.1796L12.3158 14.4023L14.52 12.1062Z"
        fill="#ED6060"
      />
    </svg>
  );
};

export const AIDAArrowForward = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.7083 9.55833L10.875 3.725C10.7142 3.56869 10.4823 3.51003 10.2665 3.57111C10.0508 3.63219 9.88402 3.80373 9.82904 4.02111C9.77406 4.23849 9.83924 4.46869 10 4.625L14.7583 9.375H3.73334C3.38816 9.375 3.10834 9.65482 3.10834 10C3.10834 10.3452 3.38816 10.625 3.73334 10.625H14.75L10 15.375C9.88088 15.4911 9.8137 15.6503 9.8137 15.8167C9.8137 15.983 9.88088 16.1423 10 16.2583C10.1149 16.3789 10.2752 16.4454 10.4417 16.4417C10.6076 16.4424 10.7668 16.3764 10.8833 16.2583L16.7167 10.425C16.9604 10.181 16.9604 9.78568 16.7167 9.54166L16.7083 9.55833Z"
        fill="#0C162A"
      />
    </svg>
  );
};
