import React from "react";

export const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      {...props}
    >
      <path
        d="M2.5211 9.16622C2.24813 9.47479 2.37198 9.89469 2.77347 9.98134C3.08784 10.0492 3.51995 10.1248 4.10205 10.1955C4.08821 10.6614 4.07977 11.202 4.07977 11.8276C4.07977 13.4954 4.13977 14.5593 4.20214 15.2146C4.25936 15.8159 4.66723 16.2739 5.25969 16.3719C6.02983 16.4992 7.36246 16.6353 9.54143 16.6353C11.7204 16.6353 13.053 16.4992 13.8232 16.3719C14.4156 16.2739 14.8235 15.8159 14.8807 15.2146C14.9431 14.5593 15.0031 13.4954 15.0031 11.8276C15.0031 11.2021 14.9947 10.6615 14.9808 10.1955C15.5631 10.1249 15.9953 10.0492 16.3098 9.98138C16.7114 9.89476 16.8352 9.47473 16.5622 9.16608C16.0831 8.62455 15.1923 7.70135 13.638 6.3331C11.8381 4.74865 10.6313 4.05937 10.0177 3.77356C9.71261 3.63145 9.37093 3.63147 9.06584 3.77361C8.45234 4.05944 7.24564 4.74874 5.44554 6.3331C3.8909 7.70145 3.00008 8.62469 2.5211 9.16622Z"
        fill="#B6B6B6"
      />
      <path
        d="M7.01199 14.2465C7.01199 13.9053 7.27695 13.6236 7.61788 13.609C8.10978 13.588 8.86419 13.564 9.74215 13.564C10.6201 13.564 11.3745 13.588 11.8664 13.609C12.2073 13.6236 12.4723 13.9053 12.4723 14.2465C12.4723 14.5877 12.2073 14.8695 11.8664 14.884C11.3745 14.905 10.6201 14.929 9.74215 14.929C8.86419 14.929 8.10978 14.905 7.61788 14.884C7.27695 14.8695 7.01199 14.5877 7.01199 14.2465Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};
