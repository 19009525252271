import {
  Box,
  Button,
  Divider,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { PageHeader } from "app/components/page-header";

import { ReactComponent as ExplorerActivitiesHeaderIcon } from "app/assets/vectors/ExplorerActivitiesHeaderIcon.svg";
import React from "react";
import { YearSelectButton } from "app/components/year-select";
import { ShareButton } from "app/components/share-button";
import { DownloadButton } from "app/components/download-button";
import { colors } from "app/theme";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { Table } from "app/components/table";
import { LIVE_DATA_UPDATES_TABLE_DATA } from "app/pages/home/explorer/components/live-data-updates/data";
import { LIVE_DATA_UPDATES_COLUMNS } from "app/pages/home/explorer/components/live-data-updates/tableColumns";
import { FilterBlock } from "./components/filter-block";

export const ExplorerActivities = () => {
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="AIDA Explorer: Search & Filter Activities"
        subtitle="Delve into the heart of humanitarian work with AIDA's activity explorer. Search, filter, and explore a vast array of aid projects and initiatives from around the world."
        icon={<ExplorerActivitiesHeaderIcon />}
      />
      <Box height={40} />
      <Grid display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h2" fontSize={"36px"}>
          Discover Impact: Search and Filter Activities
        </Typography>

        <Grid display={"flex"} alignItems={"center"} columnGap={"8px"}>
          <YearSelectButton />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={40} />
      <FilterBlock />
      <Box height={40} />

      <GenericPageBlockItem
        title="RECENT ACTIVITIES"
        description={
          <React.Fragment>
            There's currently{" "}
            <span style={{ color: colors.primary.blue }}>
              1,908 active activities{" "}
            </span>
            according to your search results.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              withSearch
              rows={LIVE_DATA_UPDATES_TABLE_DATA}
              columns={LIVE_DATA_UPDATES_COLUMNS}
              headerFontSize="12px"
            />
          </React.Fragment>
        }
        toolbarShare
      />
    </Box>
  );
};
