import React from "react";

const LocationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4314_19734)">
        <path
          d="M16.7467 9.13317C16.0467 6.05317 13.3601 4.6665 11.0001 4.6665C11.0001 4.6665 11.0001 4.6665 10.9934 4.6665C8.64006 4.6665 5.94673 6.0465 5.24673 9.1265C4.46673 12.5665 6.5734 15.4798 8.48006 17.3132C9.18673 17.9932 10.0934 18.3332 11.0001 18.3332C11.9067 18.3332 12.8134 17.9932 13.5134 17.3132C15.4201 15.4798 17.5267 12.5732 16.7467 9.13317ZM11.0001 12.4732C9.84006 12.4732 8.90006 11.5332 8.90006 10.3732C8.90006 9.21317 9.84006 8.27317 11.0001 8.27317C12.1601 8.27317 13.1001 9.21317 13.1001 10.3732C13.1001 11.5332 12.1601 12.4732 11.0001 12.4732Z"
          fill="#B6B6B6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4314_19734">
          <rect y="0.5" width="19" height="19" rx="9.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIcon;
