import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import { NavLink, useLocation } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { SkipToMainButton } from "app/components/skip-to-main-btn";
import { HeaderButtons } from "app/components/header/sub-components/buttons";
import { headerButtons } from "app/components/header/sub-components/buttons/data";
import { HeaderExpandedView } from "app/components/header/sub-components/expanded-view";
import { ReactComponent as HeaderToolbarLogo } from "app/assets/vectors/HeaderToolbarLogo.svg";

export const Header: React.FC = () => {
  const { hash, pathname } = useLocation();

  const [activeButton, setActiveButton] = React.useState<string>("");

  const handleButtonClick = (label: string) => {
    setActiveButton(label === activeButton ? "" : label);
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!hash) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 100);
  }, [pathname]);

  return (
    <Box sx={{ zIndex: 1000, flexGrow: 1, top: 0, position: "sticky" }}>
      <AppBar position="static">
        <Container maxWidth="lg" disableGutters>
          <Toolbar
            sx={{
              display: "block",
              position: "unset",
              padding: "10px 40px",
              background: colors.secondary.lightGrey,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SkipToMainButton />
              <Box width="33%" />
              <NavLink to="/" style={{ display: "flex", alignItems: "center" }}>
                <HeaderToolbarLogo />
              </NavLink>
              <HeaderButtons
                buttons={headerButtons}
                activeButton={activeButton}
                handleButtonClick={handleButtonClick}
              />
            </Box>
            {activeButton && (
              <HeaderExpandedView
                activeButton={activeButton}
                handleButtonClick={handleButtonClick}
              />
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
