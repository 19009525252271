import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { ReactComponent as ChevronIcon } from "app/assets/vectors/BreadcrumbsChevron.svg";

interface BItem {
  title: string;
  link?: string;
}

interface BreadcrumbsProps {
  items: BItem[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (
  props: BreadcrumbsProps
) => {
  return (
    <Box
      sx={{
        gap: "10px",
        display: "flex",
        padding: "20px 0",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Link
        to="/"
        style={{
          textDecoration: "none",
        }}
      >
        <Typography variant="h6" fontSize="12px" fontWeight="400">
          Home
        </Typography>
      </Link>
      {props.items.length > 0 && <ChevronIcon />}
      {props.items.map((item, index) => (
        <React.Fragment key={item.title}>
          {item.link ? (
            <Link
              to={item.link}
              style={{
                textDecoration: "none",
              }}
            >
              <Typography variant="h6" fontSize="12px" fontWeight="400">
                {item.title}
              </Typography>
            </Link>
          ) : (
            <Typography
              variant="h6"
              fontSize="12px"
              fontWeight="400"
              sx={{
                textDecoration: "underline",
              }}
            >
              {item.title}
            </Typography>
          )}
          {index < props.items.length - 1 && <ChevronIcon />}
        </React.Fragment>
      ))}
    </Box>
  );
};
