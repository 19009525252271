import React from "react";
import sumBy from "lodash/sumBy";
import { colors } from "app/theme";
import { Table } from "app/components/table";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import {
  ConvertedFile,
  GET_CONVERTED_FILES_COLUMNS,
} from "app/components/table/data";

interface UploadedDataBlockViewProps {
  files: ConvertedFile[];
  changePublishStatus: (fileId: string, value: boolean) => void;
}

export const UploadedDataBlock: React.FC<UploadedDataBlockViewProps> = (
  props: UploadedDataBlockViewProps
) => {
  const view = React.useMemo(() => {
    return (
      <Table
        withSearch
        withFilterButton
        withAddFileButton
        withColumnsButton
        rows={props.files}
        columns={GET_CONVERTED_FILES_COLUMNS(props.changePublishStatus)}
      />
    );
  }, [props.files, props.changePublishStatus]);

  const description = React.useMemo(() => {
    const activityCount = sumBy(props.files, (file) => file.activityCount);
    return (
      <React.Fragment>
        You've shared insights from{" "}
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          {activityCount} impactful activities
        </span>
      </React.Fragment>
    );
  }, [props.files]);

  return (
    <GenericPageBlockItem
      toolbarShare
      content={view}
      description={description}
      title="Your uploaded data"
    />
  );
};
