import React from "react";

export const CtaPublisherIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      {...props}
    >
      <path
        d="M43.837 63.6732C20.8088 70.4546 6.66027 71.8694 6.66027 71.8694C6.66027 71.8694 4.2118 72.6856 1.76306 70.237C-0.685672 67.7883 0.13066 65.3398 0.13066 65.3398C0.13066 65.3398 1.54551 51.1913 8.32681 28.163C9.29133 24.8876 11.9494 22.4018 15.3151 21.8261C19.7866 21.0615 26.8446 20.0942 36 19.6364L40.9408 18L54 31.0593L52.3636 36C51.906 45.1554 50.9385 52.2134 50.1739 56.6849C49.5982 60.0506 47.1124 62.7087 43.837 63.6732Z"
        fill="#294C94"
      />
      <path
        d="M36 19.6364C36.5951 12.4946 38.9144 5.28081 40.1483 1.88471C40.6015 0.637479 41.7919 -0.151445 43.1072 0.0244479C46.9093 0.532882 55.0473 2.45279 62.2972 9.70267C69.5471 16.9526 71.467 25.0907 71.9756 28.8928C72.1515 30.2081 71.3625 31.3985 70.1152 31.8518C66.7191 33.0856 59.5054 35.4048 52.3636 36C52.3636 36 50.7922 31.0693 45.8615 26.1386C40.9307 21.2078 36 19.6364 36 19.6364Z"
        fill="#294C94"
      />
      <path
        d="M0.594452 68.6794C4.6066 64.2484 13.6103 54.3245 21.3216 45.9671C21.1817 45.4345 21.1071 44.8754 21.1071 44.2989C21.1071 40.6838 24.0377 37.7534 27.6526 37.7534C31.2676 37.7534 34.198 40.6838 34.198 44.2989C34.198 47.9138 31.2676 50.8443 27.6526 50.8443C27.0761 50.8443 26.5169 50.7697 25.9845 50.6298C17.6169 58.3503 7.67951 67.3662 3.25631 71.3714C2.77291 71.1082 2.26806 70.7422 1.76321 70.2374C1.23571 69.71 0.859723 69.1824 0.594452 68.6794Z"
        fill="#182B54"
      />
    </svg>
  );
};
