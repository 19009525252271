import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";

interface PublisherBlockPreVizPopupProps {
  open: boolean;
  onClose: () => void;
}

const blocks = [
  {
    title: "Overview",
    description: (
      <React.Fragment>
        Your published data reveals a{" "}
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          456K EUR
        </span>{" "}
        impact across{" "}
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          3 recipient countries.
        </span>
      </React.Fragment>
    ),
    content: <React.Fragment />,
    md: 6,
  },
  {
    title: "Impact",
    description: (
      <React.Fragment>
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          6 activities
        </span>{" "}
        surpassed their targets results according to your available published
        results data.
      </React.Fragment>
    ),
    content: <React.Fragment />,
    md: 6,
  },
  {
    title: "Locations",
    description: (
      <React.Fragment>
        Your impact shines in{" "}
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          Somalia, Yemen, South Sudan and Afghanistan.
        </span>
      </React.Fragment>
    ),
    content: <React.Fragment />,
  },
  {
    title: "Sustainable Development Goals",
    description: (
      <React.Fragment>
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          2 activities
        </span>{" "}
        were contributing to the SDGs according to your published data.
      </React.Fragment>
    ),
    content: <React.Fragment />,
  },
  {
    title: "Results",
    description: (
      <React.Fragment>
        Your data results show activities surpassed about{" "}
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          4.8%
        </span>{" "}
        of their target results on average.
      </React.Fragment>
    ),
    content: <React.Fragment />,
  },
  {
    title: "Networks",
    description: (
      <React.Fragment>
        You have collaborated with{" "}
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          6 other organisations
        </span>{" "}
        to have a positive impact in the world.
      </React.Fragment>
    ),
    content: <React.Fragment />,
  },
  {
    title: "Funding",
    description: (
      <React.Fragment>
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          Productive Sectors
        </span>{" "}
        were the most funded 3-digit sector.
      </React.Fragment>
    ),
    content: <React.Fragment />,
  },
];

export const PublisherBlockPreVizPopup: React.FC<
  PublisherBlockPreVizPopupProps
> = (props: PublisherBlockPreVizPopupProps) => {
  return (
    <Modal
      keepMounted
      open={props.open}
      closeAfterTransition
      onClose={props.onClose}
      sx={{
        paddingTop: "100px",
        alignItems: "flex-start",
      }}
    >
      <Fade in={props.open}>
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            display: "flex",
            maxWidth: "1200px",
            borderRadius: "8px",
            flexDirection: "column",
            padding: "20px 40px 40px 40px",
            background: colors.primary.white,
            boxShadow: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={props.onClose}>
              <Close htmlColor={colors.secondary.iconGray} />
            </IconButton>
          </Box>
          <Grid
            container
            spacing={1}
            sx={{
              "> div > div": {
                border: `1px solid ${colors.secondary.iconGray}`,
              },
            }}
          >
            {blocks.map((block) => (
              <Grid item xs={12} sm={6} md={block.md} key={block.title}>
                <GenericPageBlockItem {...block} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};
