import React from "react";

export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        d="M9.91675 6.35569H11.5834V8.02236H9.91675V6.35569ZM9.91675 9.68903H11.5834V14.689H9.91675V9.68903ZM10.7501 2.18903C6.15008 2.18903 2.41675 5.92236 2.41675 10.5224C2.41675 15.1224 6.15008 18.8557 10.7501 18.8557C15.3501 18.8557 19.0834 15.1224 19.0834 10.5224C19.0834 5.92236 15.3501 2.18903 10.7501 2.18903ZM10.7501 17.189C7.07508 17.189 4.08341 14.1974 4.08341 10.5224C4.08341 6.84736 7.07508 3.85569 10.7501 3.85569C14.4251 3.85569 17.4167 6.84736 17.4167 10.5224C17.4167 14.1974 14.4251 17.189 10.7501 17.189Z"
        fill="#888888"
      />
    </svg>
  );
};
