import React from "react";
import { ChartPlaceholder, KeyProjectionsBlockProps } from "./data";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { colors } from "app/theme";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { PieChart } from "app/components/charts/pie";
import { PieChartSampleData } from "app/components/charts/pie/data";
import { ImpactChart } from "app/components/charts/impact";
import { IMPACT_CHART_ITEMS } from "app/components/charts/impact/data";

export const KeyProjectionsBlock: React.FC<KeyProjectionsBlockProps> = (
  props: KeyProjectionsBlockProps
) => {
  const overview = React.useMemo(() => {
    const items = [
      { name: "Disbursements and Expenditures", value: "USD 30 billion" },
      { name: "Activities", value: "12,474" },
      { name: "Organisations", value: "356" },
      { name: "Recipient Countries", value: "92" },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        title="2050 Overview"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD 30 billion
            </span>{" "}
            is set to be spent for
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {" "}
              92 recipient countries
            </span>{" "}
            up to 2050.
          </React.Fragment>
        }
        content={<OverviewBlock items={items} />}
      />
    );
  }, []);

  const locations = React.useMemo(() => {
    const items = [
      {
        code: "so",
        name: "Somalia",
        funds: "USD 9.51 mln",
        activities: 59,
      },
      {
        code: "ye",
        name: "Yemen",
        funds: "USD 9.51 mln",
        activities: 26,
      },
      {
        code: "ss",
        name: "South Sudan",
        funds: "USD 6.65 mln",
        activities: 64,
      },
      {
        code: "af",
        name: "Afghanistan",
        funds: "USD 5.36 mln",
        activities: 25,
      },
    ];
    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          title="2050 Countries"
          onExpand={() => props.setExpandedBlock("key-locations")}
          description={
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                Somalia, Yemen, South Sudan
              </span>{" "}
              and{" "}
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                Afghanistan{" "}
              </span>
              were the largest recipient countries of 2022.
            </React.Fragment>
          }
          content={<LocationsBlock items={items} />}
          expanded={props.expandedBlock === "key-locations"}
        />
        {props.expandedBlock === "key-locations" && (
          <Box
            height="650px"
            padding="0 30px 20px 30px"
            bgcolor={colors.primary.white}
          >
            <Divider />
          </Box>
        )}
      </React.Fragment>
    );
  }, [props.expandedBlock]);

  const organisations = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="2050 Organisations"
        onExpand={() => props.setExpandedBlock("key-organisations")}
        height="100%"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              European Union
            </span>{" "}
            will be one of the important organisations shaping our future
            leading up to 2050.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              Organisations engaged in activities spanning until 2050:
            </Typography>
            <Box height={20} />
            <PieChart
              id="key-projections-organisations"
              data={PieChartSampleData}
              unit="USD"
              height={
                props.expandedBlock === "key-organisations" ? "500px" : "333px"
              }
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "key-organisations"}
      />
    );
  }, [props.expandedBlock]);

  const sectors = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="2050 Sectors"
        height="100%"
        onExpand={() => props.setExpandedBlock("key-sectors")}
        description={
          <React.Fragment>
            The{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              humanitarian aid
            </span>{" "}
            sector is anticipated to secure the most substantial funding as we
            approach 2050.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              Here's a curated collection of the visionary activities planned to
              extend their impact until 2050.
            </Typography>
            <Box height={20} />
            <PieChart
              id="key-projections-sectors"
              half
              donut
              data={PieChartSampleData}
              unit="USD"
              height={props.expandedBlock === "key-sectors" ? "500px" : "424px"}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "key-sectors"}
      />
    );
  }, [props.expandedBlock]);

  const targets = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="2050 Targets"
        onExpand={() => props.setExpandedBlock("key-targets")}
        description={
          <React.Fragment>
            Total of{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              1,457 activities
            </span>{" "}
            have been meticulously planned to extend their efforts well into the
            future, all with a resolute commitment to shaping a brighter world
            by the year 2050.
          </React.Fragment>
        }
        content={<ImpactChart items={[...IMPACT_CHART_ITEMS]} />}
        expanded={props.expandedBlock === "key-targets"}
      />
    );
  }, [props.expandedBlock]);

  const expandedBlockView = React.useMemo(() => {
    switch (props.expandedBlock) {
      case "key-locations":
        return locations;
      case "key-organisations":
        return organisations;

      case "key-sectors":
        return sectors;
      case "key-targets":
        return targets;
      default:
        return null;
    }
  }, [props.expandedBlock, locations, organisations, sectors, targets]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Countries"),
    [locations]
  );

  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Organisations"),
    [organisations]
  );

  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Sectors"),
    [sectors]
  );

  const targetsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Targets"),
    [targets]
  );

  return (
    <Grid container spacing={4}>
      {props.expandedBlock && (
        <Grid item xs={12}>
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} md={7}>
        {overview}
        <Box sx={{ height: 20 }} />
        {props.expandedBlock !== "key-locations" ? (
          locations
        ) : (
          <ChartPlaceholder height={locationsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        {props.expandedBlock !== "key-organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={7}>
        {props.expandedBlock !== "key-sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        {props.expandedBlock !== "key-targets" ? (
          targets
        ) : (
          <ChartPlaceholder height={targetsBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
