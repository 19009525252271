import {
  Box,
  Button,
  Divider,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { MinusIcon } from "app/assets/vectors/jsx/MinusIcon";
import { PlusIcon } from "app/assets/vectors/jsx/PlusIcon";
import { ResetIcon } from "app/assets/vectors/jsx/ResetIcon";
import { SearchInput } from "app/components/search-input";
import { SelectField } from "app/components/select-field";
import { colors } from "app/theme";
import React from "react";

const ResetButtonStyle = {
  background: colors.shades.blue[100],
  padding: "6px 12px",
  lineHeight: "20px",
  fontWeight: 400,
  color: colors.text.body,
  borderRadius: "5px",
};

const inputStyle = {
  border: `1px solid ${colors.primary.blue}`,
  borderRadius: "5px",
  background: colors.secondary.lightGrey,
  fontWeight: "12px",
  color: colors.text.title,
  height: "40px",
  padding: "0px 20px",
  outline: "none",
  width: "100%",
};

export const FilterBlock: React.FC = () => {
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState({
    location: [],
    organisations: [],
    sectors: [],
    year1: "",
    year2: "",
    activityStatus: [],
    budgetMin: "",
    budgetMax: "",
  });

  const handleChange = (e: SelectChangeEvent) => {
    setSelectedFilters({ ...selectedFilters, [e.target.name]: e.target.value });
  };
  return (
    <Box
      sx={{
        padding: "20px 30px",
        background: colors.primary.white,
      }}
    >
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="subtitle2" fontSize={"18px"} lineHeight={"24px"}>
          Search through 58,098 activities.
        </Typography>
        <Grid display={"flex"} alignItems={"center"} columnGap={"20px"}>
          <Button sx={ResetButtonStyle}>No filters added yet</Button>
          <Button sx={ResetButtonStyle} endIcon={<ResetIcon />}>
            Reset Filters
          </Button>
        </Grid>
      </Grid>
      <Box height={20} />
      <SearchInput placeholder="Search" showBorder />
      <Box height={40} />
      <Grid display={"flex"} alignItems={"center"} columnGap={"20px"}>
        <Divider
          sx={{
            flex: 1,
            borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
          }}
        />
        <Button
          variant="contained"
          sx={{
            background: filtersOpen ? colors.shades.blue[400] : "auto",
          }}
          endIcon={filtersOpen ? <MinusIcon /> : <PlusIcon />}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          Add Filters
        </Button>
        <Divider
          sx={{
            flex: 1,
            borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
          }}
        />
      </Grid>

      <Grid
        sx={{
          maxHeight: filtersOpen ? "250px" : 0,
          overflow: "hidden",
          margin: filtersOpen ? "40px 0" : "10px 0",
          transition: "all 0.3s ease-in-out",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          display: "grid",
          gap: "20px",
        }}
      >
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom={"10px"}>
            Location
          </Typography>
          <SelectField
            multiple
            variant="outlined"
            id="location"
            name="location"
            value={selectedFilters.location}
            onChange={handleChange}
            fontWeight="400"
            height="40px"
            placeholder="Select one or multiple options"
            options={[
              { value: "1", label: "Location 1" },
              { value: "2", label: "Location 2" },
            ]}
            borderColor={colors.primary.blue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom={"10px"}>
            Organisations
          </Typography>
          <SelectField
            multiple
            variant="outlined"
            id="organisations"
            name="organisations"
            value={selectedFilters.organisations}
            onChange={handleChange}
            fontWeight="400"
            height="40px"
            placeholder="Select one or multiple options"
            options={[
              { value: "1", label: "Location 1" },
              { value: "2", label: "Location 2" },
            ]}
            borderColor={colors.primary.blue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom={"10px"}>
            Sectors
          </Typography>
          <SelectField
            multiple
            variant="outlined"
            id="sectors"
            name="sectors"
            value={selectedFilters.sectors}
            onChange={handleChange}
            height="40px"
            placeholder="Select one or multiple options"
            fontWeight="400"
            options={[
              { value: "1", label: "Location 1" },
              { value: "2", label: "Location 2" },
            ]}
            borderColor={colors.primary.blue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom={"10px"}>
            Year Range
          </Typography>
          <Grid display={"flex"} columnGap={"10px"}>
            <SelectField
              variant="outlined"
              id="year1"
              name="year1"
              value={selectedFilters.year1}
              onChange={handleChange}
              height="40px"
              placeholder="Select an option"
              fontWeight="400"
              options={[
                { value: "1", label: "Location 1" },
                { value: "2", label: "Location 2" },
              ]}
              borderColor={colors.primary.blue}
            />
            <SelectField
              variant="outlined"
              id="year2"
              name="year2"
              value={selectedFilters.year2}
              onChange={handleChange}
              height="40px"
              placeholder="Select an option"
              fontWeight="400"
              options={[
                { value: "1", label: "Location 1" },
                { value: "2", label: "Location 2" },
              ]}
              borderColor={colors.primary.blue}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom={"10px"}>
            Activity Status
          </Typography>
          <SelectField
            width="95%"
            multiple
            variant="outlined"
            id="sectors"
            name="sectors"
            value={selectedFilters.sectors}
            onChange={handleChange}
            height="40px"
            placeholder="Select one or multiple options"
            fontWeight="400"
            options={[
              { value: "1", label: "Location 1" },
              { value: "2", label: "Location 2" },
            ]}
            borderColor={colors.primary.blue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom={"10px"}>
            Total Budget
          </Typography>
          <Grid
            display={"flex"}
            columnGap={"10px"}
            sx={{
              "> input": inputStyle,
              width: "100%",
            }}
          >
            <input
              onChange={handleChange}
              value={selectedFilters.budgetMin}
              name="budgetMin"
              placeholder="Minimum"
            />
            <input
              onChange={handleChange}
              value={selectedFilters.budgetMax}
              name="budgetMax"
              placeholder="Maximum"
            />
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{
          margin: "0 auto",
          display: "block",
        }}
      >
        Search
      </Button>
    </Box>
  );
};
