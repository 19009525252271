import { Box, Typography } from "@mui/material";
import { UpDownIcon } from "app/assets/vectors/jsx/UpDownIcon";
import { ReactComponent as ListIcon } from "app/assets/vectors/GenericListIcon.svg";
import { GenericTableColumn } from "app/components/generic-table/data";
import { colors } from "app/theme";
import { HomeIcon } from "app/assets/vectors/jsx/HomeIcon";
import { BallTriangleIcon } from "app/assets/vectors/jsx/BallTriangleIcon";
import { AIDAArrowForward } from "app/assets/vectors/jsx/arrow";
import LocationIcon from "app/assets/vectors/jsx/LocationIcon";

export const SECTOR_COLUMNS: GenericTableColumn[] = [
  {
    field: "sector",
    headerName: "Sector",
    align: "left",
    headerAlign: "left",
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "incomingFunds",
    headerName: "Incoming Funds",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <UpDownIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "activities",
    headerName: "Activities",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <ListIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "locations",
    headerName: "Locations",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <LocationIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "organisations",
    headerName: "Organisations",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <HomeIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },

  {
    field: "highlights",
    headerName: "Sector highlights reflect 2022 data.",
    headerAlign: "right",
    headerStyle: {
      color: colors.text.title,
      fontWeight: 400,
      justifyContent: "flex-end",
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
        <AIDAArrowForward />
      </Box>
    ),
  },
];
