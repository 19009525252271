import { Box, IconButton, Typography } from "@mui/material";
import { UpDownIcon } from "app/assets/vectors/jsx/UpDownIcon";
import { ReactComponent as ListIcon } from "app/assets/vectors/GenericListIcon.svg";
import { GenericTableColumn } from "app/components/generic-table/data";
import { colors } from "app/theme";
import { CircleFlag } from "react-circle-flags";
import { HomeIcon } from "app/assets/vectors/jsx/HomeIcon";
import { BallTriangleIcon } from "app/assets/vectors/jsx/BallTriangleIcon";
import { AIDAArrowForward } from "app/assets/vectors/jsx/arrow";
import { Link } from "react-router-dom";

export const LOCATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "location",
    headerName: "Location",
    align: "left",
    headerAlign: "left",
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"8px"}>
        <CircleFlag
          width={12}
          height={12}
          countryCode={params.row.code.toLowerCase()}
        />

        <Typography variant="body2" fontSize={"12px"} color={colors.text.title}>
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "incomingFunds",
    headerName: "Incoming Funds",
    width: 130,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <UpDownIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "activities",
    headerName: "Activities",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <ListIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "organisations",
    headerName: "Organisations",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <HomeIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "sectors",
    headerName: "Sectors",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <BallTriangleIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "highlights",
    headerName: "Location highlights reflect 2022 data.",
    headerAlign: "right",
    headerStyle: {
      color: colors.text.title,
      fontWeight: 400,
      justifyContent: "flex-end",
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
        <IconButton
          sx={{
            padding: 0,
          }}
          component={Link}
          to={`/explorer/locations/${params.row.code}`}
        >
          <AIDAArrowForward />
        </IconButton>
      </Box>
    ),
  },
];
