export interface LineChartProps {
  id: string;
  data: { x: string | number; y: number }[];
  yAxisLabelName: string;
  height?: string;
  width?: string;
  variant?: "default" | "signalLine";
}

export const LineChartSampleData = [
  {
    x: "2018",
    y: 0.2,
  },
  {
    x: "2019",
    y: 1.5,
  },
  {
    x: "2020",
    y: -3,
  },
  {
    x: "2021",
    y: 2.5,
  },
  {
    x: "2022",
    y: 3.5,
  },
];

export const SignalLineChartSampleData = [
  {
    x: "2020",
    y: 20000,
  },
  {
    x: "2021",
    y: 15000,
  },
  {
    x: "2022",
    y: 30000,
  },
  {
    x: "2023",
    y: 25000,
  },
  {
    x: "2024",
    y: 35000,
  },
];
