export interface NetworkChartProps {
  data: {
    nodes: {
      id: string;
      name: string;
      symbolSize: number;
    }[];
    links: {
      source: string;
      target: string;
    }[];
    categories: {
      name: string;
    }[];
  };
  height?: string;
  zoom?: number;
}

export const NetworkSampleData = {
  nodes: [
    {
      id: "1",
      name: "Node 1",
      symbolSize: 30,
      category: 0,
    },
    {
      id: "2",
      name: "Node 2",
      symbolSize: 20,
      category: 1,
    },
    {
      id: "3",
      name: "Node 3",
      symbolSize: 10,
      category: 0,
    },
    {
      id: "4",
      name: "Node 4",
      symbolSize: 15,
      category: 2,
    },
    {
      id: "5",
      name: "Node 5",
      symbolSize: 25,
      category: 1,
    },
    {
      id: "6",
      name: "Node 6",
      symbolSize: 5,
      category: 0,
    },
    {
      id: "7",
      name: "Node 7",
      symbolSize: 10,
      category: 2,
    },
  ],
  links: [
    {
      source: "1",
      target: "2",
    },
    {
      source: "1",
      target: "3",
    },
    {
      source: "1",
      target: "4",
    },
    {
      source: "1",
      target: "5",
    },
    {
      source: "1",
      target: "6",
    },
    {
      source: "1",
      target: "7",
    },
    {
      source: "2",
      target: "3",
    },
    {
      source: "2",
      target: "4",
    },
    {
      source: "2",
      target: "5",
    },
    {
      source: "2",
      target: "6",
    },
    {
      source: "2",
      target: "7",
    },
    {
      source: "3",
      target: "4",
    },
    {
      source: "3",
      target: "5",
    },
    {
      source: "3",
      target: "6",
    },
    {
      source: "3",
      target: "7",
    },
    {
      source: "4",
      target: "5",
    },
    {
      source: "4",
      target: "6",
    },
    {
      source: "4",
      target: "7",
    },
    {
      source: "5",
      target: "6",
    },
    {
      source: "5",
      target: "7",
    },
    {
      source: "6",
      target: "7",
    },
  ],
  categories: [
    { name: "Category 1" },
    { name: "Category 2" },
    { name: "Category 3" },
  ],
};
