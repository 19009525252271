import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { colors } from "app/theme";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";

const TransactionTypeSelect = () => {
  const [transactionType, setTransactionType] =
    React.useState("incoming_funds");

  const handleChange = (event: SelectChangeEvent) => {
    setTransactionType(event.target.value as string);
  };

  const options = [
    { value: "incoming_funds", label: "Incoming Funds" },
    { value: "outgoing_commitment", label: "Outgoing Commitment" },
    { value: "disbursement", label: "Disbursement" },
    { value: "expenditure", label: "Expenditure" },
    { value: "interest_payment", label: "Interest Payment" },
    { value: "loan_payment", label: "Loan Payment" },
    { value: "reimbursement", label: "Reimbursement" },
    { value: "purchase_of_equity", label: "Purchase of Equity" },
    { value: "sale_of_equity", label: "Sale of Equity" },
    { value: "credit_guarantee", label: "Credit Guarantee" },
    { value: "incoming_commitment", label: "Incoming Commitment" },
    { value: "outgoing_pledge", label: "Outgoing Pledge" },
    { value: "incoming_pledge", label: "Incoming Pledge" },
  ];

  return (
    <FormControl
      fullWidth
      sx={{
        ".MuiInputBase-root": {
          width: "100%",
          height: "32px",
          borderRadius: "5px",
          background: colors.primary.blue,
        },
        ".MuiInputBase-input": {
          fontSize: "12px",
          fontWeight: "700",
          textAlign: "start",
          padding: "0px 8px",
          color: colors.primary.white,
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderStyle: "none",
        },
        ".MuiSelect-icon": {
          color: colors.primary.white,
        },
        ".MuiMenuItem-root": {
          fontSize: "12px",
          fontWeight: "700",
        },
        ".MuiSelect-paper": {
          background: `colors.primary.green !important`,
          padding: "100px",
          borderRadius: "10px",
          border: `1px solid ${colors.secondary.iconGray}`,
        },
      }}
    >
      <Select
        value={transactionType}
        IconComponent={ArrowDown}
        onChange={handleChange}
        MenuProps={{
          sx: {
            "& ul": {
              background: colors.secondary.lightGrey,
              padding: "10px",
              borderRadius: "10px",
              border: `1px solid ${colors.secondary.iconGray}`,
              maxHeight: "350px",
              overflowY: "scroll",
              marginTop: "8px",
              width: "224px",
            },
            ".Mui-selected": {
              background: colors.secondary.disableButton,
              borderRadius: "10px",
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              marginBottom: "10px",
              ":hover": {
                borderRadius: "10px",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TransactionTypeSelect;
