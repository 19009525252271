import { Box, Typography } from "@mui/material";
import { PageHeader } from "app/components/page-header";
import { ReactComponent as ExplorerFavouritesHeaderIcon } from "app/assets/vectors/ExplorerFavouritesHeaderIcon.svg";
import React from "react";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { colors } from "app/theme";
import { FAVOURITES_TABLE_DATA } from "./data";
import { FAVOURITES_COLUMNS } from "./tableColums";
import { Table } from "app/components/table";
import { FavouriteIcon } from "app/assets/vectors/jsx/FavouriteIcon";

export const ExplorerFavourites: React.FC = () => {
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Your Favourites: Aid Insights, At Your Fingertips"
        subtitle="Introducing 'Your Bookmarks' - your personalized area for humanitarian insights. Save and organize your favorite activities for quick access. This feature is designed with you in mind, making it easier to revisit critical information."
        icon={<ExplorerFavouritesHeaderIcon />}
      />
      <Box height={40} />
      {FAVOURITES_TABLE_DATA.length > 0 ? (
        <GenericPageBlockItem
          title="Your favourites"
          description={
            <React.Fragment>
              You have favourited{" "}
              <span style={{ color: colors.primary.blue }}>
                146 data files.
              </span>
            </React.Fragment>
          }
          content={
            <React.Fragment>
              <Table
                withSearch
                rows={FAVOURITES_TABLE_DATA}
                columns={FAVOURITES_COLUMNS}
                headerFontSize="12px"
                withSortButton
              />
            </React.Fragment>
          }
        />
      ) : (
        <Box
          sx={{
            background: colors.primary.white,
            padding: "50px 100px",
            height: "calc(100vh - 628px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "30px",
          }}
        >
          <FavouriteIcon height={67} width={67} />
          <Box>
            <Typography variant="h2" fontSize={"36px"}>
              Add favourite activities for future reference!
            </Typography>
            <Typography
              variant="h5"
              fontSize={"14px"}
              fontWeight={400}
              lineHeight={"20px"}
            >
              Start marking activities now to follow the recent updates &
              archive your interests.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
