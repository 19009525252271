import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import filter from "lodash/filter";
import Box from "@mui/material/Box";
import orderBy from "lodash/orderBy";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

interface LandingPageCarouselProps {
  children: React.ReactNode[];
}

export function LandingPageCarousel(props: LandingPageCarouselProps) {
  const scrollableRef = React.useRef<HTMLDivElement>(null);

  const [backBtnEnabled, setBackBtnEnabled] = React.useState(false);
  const [forwardBtnEnabled, setForwardBtnEnabled] = React.useState(true);

  const onArrowButtonClick = (direction: "left" | "right") => () => {
    if (!scrollableRef.current) return;
    scrollableRef.current.scroll({
      left:
        scrollableRef.current.scrollLeft +
        (direction === "left" ? -25 : 25) +
        scrollableRef.current.clientWidth * (direction === "left" ? -1 : 1),
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    if (!scrollableRef.current) return;
    const scrollable = scrollableRef.current;
    const onScroll = () => {
      if (!scrollable) return;
      const { scrollLeft, scrollWidth, clientWidth } = scrollable;
      const scrollRight = scrollWidth - scrollLeft - clientWidth;
      const backBtnEnabled = scrollLeft > 0;
      const forwardBtnEnabled = scrollRight > 0;
      setBackBtnEnabled(backBtnEnabled);
      setForwardBtnEnabled(forwardBtnEnabled);
    };
    scrollable.addEventListener("scroll", onScroll);
    return () => scrollable.removeEventListener("scroll", onScroll);
  }, [scrollableRef.current]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        stroke: colors.shades.green[600],
        "button.Mui-disabled": {
          stroke: colors.secondary.iconGray,
        },
        "@media (max-width: 768px)": {
          height: "100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          aria-label="Scroll left"
          aria-disabled={props.children.length === 0 || !backBtnEnabled}
          onClick={onArrowButtonClick("left")}
          disabled={props.children.length === 0 || !backBtnEnabled}
          sx={{ padding: "0" }}
        >
          <ArrowBackIos
            fontSize="medium"
            sx={{
              transform: "translate(5px, 0)",
            }}
          />
        </IconButton>
      </Box>
      <Box
        ref={scrollableRef}
        sx={{
          gap: "25px",
          padding: "0 10px",
          display: "flex",
          maxWidth: "100%",
          overflowX: "auto",
          width: "100%",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "@media (max-width: 768px)": {
            width: "100%",
          },
          "> div": {
            gap: "10px",
            display: "flex",
            minWidth: "100%",
            maxWidth: "100%",
            // minHeight: "287px",
            // maxHeight: "287px",
            // padding: "30px 50px",
            // borderRadius: "20px",
            flexDirection: "column",
            // boxShadow: colors.shadows.main,
            background: colors.primary.white,
            "@media (max-width: 920px)": {
              padding: "30px",
            },
            "@media (max-width: 768px)": {
              padding: "16px",
              minHeight: "400px",
              maxHeight: "400px",
            },
          },
        }}
      >
        {props.children}

        {props.children.length === 0 && (
          <Box
            component="span"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1">No data available</Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          aria-label="Scroll right"
          aria-disabled={props.children.length === 0 || !forwardBtnEnabled}
          onClick={onArrowButtonClick("right")}
          disabled={props.children.length === 0 || !forwardBtnEnabled}
          sx={{ padding: "0" }}
        >
          <ArrowBackIos
            fontSize="medium"
            sx={{
              transform: "rotate(180deg) translate(5px, 0)",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}
