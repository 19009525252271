import React from "react";
import Box from "@mui/material/Box";
import { Header } from "app/components/header";
import { Footer } from "app/components/footer";
import Container from "@mui/material/Container";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import { Breadcrumbs } from "app/components/breadcrumbs";
import { useRouteListener } from "app/hooks/useRouteListener";
import { SAMPLE_LOCATIONS_DATA } from "../generic-table/data";

export const Page: React.FC = () => {
  useRouteListener();

  const location = useLocation();

  const getLocationName = (code: string) => {
    return (
      SAMPLE_LOCATIONS_DATA.find(
        (location) => location.code.toLowerCase() === code.toLowerCase()
      )?.location ?? ""
    );
  };

  const breadcrumbItems = React.useMemo(() => {
    if (location.pathname === "/publisher") {
      return [{ title: "Publisher", link: "/publisher" }];
    }
    if (location.pathname === "/explorer") {
      return [{ title: "Explore", link: "/explorer" }];
    }
    if (location.pathname === "/explorer/locations") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Locations", link: "/explorer/locations" },
      ];
    }
    const locationID = matchPath("/explorer/locations/:id", location.pathname)
      ?.params.id;
    if (locationID) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Locations", link: "/explorer/locations" },
        { title: getLocationName(locationID), link: location.pathname },
      ];
    }
    if (location.pathname === "/explorer/organisations") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Organisations", link: "/explorer/organisations" },
      ];
    }
    if (location.pathname === "/explorer/sectors") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Sectors", link: "/explorer/sectors" },
      ];
    }
    if (location.pathname === "/explorer/favourites") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Your Favourites", link: "/explorer/favourites" },
      ];
    }
    if (location.pathname === "/explorer/activities") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Activities", link: "/explorer/activities" },
      ];
    }
    if (location.pathname === "/data-hub") {
      return [{ title: "Data Hub", link: "/data-hub" }];
    }
    return [];
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Header />
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          paddingBottom: "50px",
          minHeight: "calc(100vh - 51px - 277px)",
          "@media (max-width: 1200px)": {
            padding: "0 16px",
          },
        }}
      >
        {location.pathname === "/" ? (
          <Box height={50} />
        ) : (
          <Breadcrumbs items={breadcrumbItems} />
        )}

        <Box id="main">
          <Outlet />
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
