import { Box, Button, Grid, Popper, Typography } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { colors } from "app/theme";
import React from "react";
import {
  ACTIVITY_COLUMNS,
  LOCATION_COLUMNS,
  ORGANISATION_COLUMNS,
  SECTOR_COLUMNS,
} from "./tableColumns";
import { ACTIVITIES, SECTORS, ORGANISATIONS, LOCATIONS } from "./data";
import { GenericTable } from "app/components/generic-table";

interface ExplorerSearchResultsPopupProps {
  anchorEl: HTMLInputElement | null;
  id: string;
  handleClose: () => void;
}

const ButtonStyle = {
  fontSize: "12px",
  fontWeight: "700",
  textTransform: "none",
  color: colors.primary.white,
  background: colors.primary.blue,
  padding: "6px 10px",
};

type TabType = "activities" | "locations" | "organisations" | "sectors";

interface TabProps {
  children: React.ReactNode;
  activeTab: TabType;
  setActiveTab: React.Dispatch<React.SetStateAction<TabType>>;
  value: TabType;
}
const Tab = (props: TabProps) => {
  const active = props.activeTab === props.value;
  return (
    <Box
      sx={{
        width: "200px",
        padding: "8px 0px",
        borderBottom: `3px solid ${active ? colors.primary.blue : colors.shades.blue["200"]}`,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => props.setActiveTab(props.value)}
    >
      <Typography variant="h5" fontSize={"14px"}>
        {props.children}
      </Typography>
    </Box>
  );
};

export const ExplorerSearchResultsPopup = (
  props: ExplorerSearchResultsPopupProps
) => {
  const open = Boolean(props.anchorEl);
  const id = open ? props.id : undefined;
  const [activeTab, setActiveTab] = React.useState<TabType>("activities");

  const getTableContent = (tab: TabType) => {
    return {
      activities: { columns: ACTIVITY_COLUMNS, rows: ACTIVITIES },
      locations: { columns: LOCATION_COLUMNS, rows: LOCATIONS },
      organisations: { columns: ORGANISATION_COLUMNS, rows: ORGANISATIONS },
      sectors: { columns: SECTOR_COLUMNS, rows: SECTORS },
    }[tab];
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={props.anchorEl}
      placement={"bottom"}
      sx={{ zIndex: 3 }}
    >
      <Box
        sx={{
          width: "1058px",
          borderRadius: "5px",
          marginTop: "7px",
          background: colors.secondary.lightGrey,
          border: "1px solid #A1AEBD",
          padding: "16px",
        }}
      >
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Tab
            value={"activities"}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            Activities (345)
          </Tab>
          <Tab
            value={"locations"}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            Locations (2)
          </Tab>
          <Tab
            value={"organisations"}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            Oranisations (40)
          </Tab>
          <Tab
            value={"sectors"}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            Sectors (34)
          </Tab>
        </Grid>

        <Box height={10} />
        <Box>
          <GenericTable
            id="explorer-table"
            columns={getTableContent(activeTab).columns}
            rows={getTableContent(activeTab).rows}
          />
        </Box>
        <Box height={10} />
        <Grid display={"flex"} justifyContent={"flex-end"} columnGap={"10px"}>
          <Button sx={ButtonStyle} variant="contained">
            Close
          </Button>
          <Button
            sx={ButtonStyle}
            variant="contained"
            endIcon={<ArrowForward />}
          >
            Search
          </Button>
        </Grid>
      </Box>
    </Popper>
  );
};
