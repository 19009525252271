import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { SDGChartProps } from "./data";

export const SDGChart: React.FC<SDGChartProps> = (props: SDGChartProps) => {
  const size = props.expanded ? "125px" : "150px";
  const gap = props.expanded ? "20px" : "10px";
  return (
    <Grid display={"flex"} gap={gap} flexWrap={"wrap"}>
      {props.data.map((d, i) => (
        <Box key={i}>
          <Box
            height={size}
            width={size}
            sx={{
              "> img": {
                width: "100%",
                height: "100%",
                objectFit: "contain",
              },
            }}
          >
            <img src={`/sdgs/en/${d.sdg}.png`} alt={`SDG ${d.sdg}`} />
          </Box>
          {props.expanded ? (
            <Box
              sx={{
                padding: "10px",
                textAlign: "center",
              }}
            >
              <Typography variant="h6">{d.value}</Typography>
              <Typography
                variant="body2"
                fontSize={"12px"}
                sx={{ marginTop: "5px" }}
              >
                {props.labelGroup}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ))}
    </Grid>
  );
};
