import { Box, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ACTIVITY_STATUS_COLORS } from "app/pages/explorer/components/search/tableColumns";
import { CircleFlag } from "react-circle-flags";
import { get } from "lodash";
import { FavouriteIcon } from "app/assets/vectors/jsx/FavouriteIcon";
import { colors } from "app/theme";

export const LIVE_DATA_UPDATES_COLUMNS: GridColDef[] = [
  {
    field: "favourite",
    headerName: "Favourite",
    width: 76,
    align: "center",

    renderCell: (params: any) => (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FavouriteIcon />
      </Box>
    ),
  },
  {
    field: "title",
    headerName: "Activity Title",
    width: 500,
    flex: 1,
  },
  {
    field: "location",
    headerName: "Location(s)",
    width: 150,
    renderCell: (params: any) => (
      <Grid
        display={"flex"}
        alignItems={"center"}
        columnGap={"5px"}
        height={"100%"}
      >
        <CircleFlag
          countryCode={params.row.locationCode.toLowerCase()}
          height="12"
          width="12"
        />
        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={400}>
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <Grid
        display={"flex"}
        alignItems={"center"}
        columnGap={"6px"}
        height={"100%"}
      >
        <Box
          sx={{
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            flexShrink: 0,
            background: get(ACTIVITY_STATUS_COLORS, params.value, "#333ED4"),
          }}
        />
        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={400}>
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "totalCommitment",
    headerName: "Total Commitment",
    width: 150,
    renderCell: (params: any) => (
      <Grid
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        height={"100%"}
        rowGap={"2px"}
      >
        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={400}>
          {params.value}
        </Typography>
        <Box
          sx={{
            background: colors.secondary.disableText,
            width: "180px",
            height: "5px",
          }}
        >
          <Box
            sx={{
              background: colors.primary.blue,
              width: `${params.row.commitmentPercentage}%`,
              height: "100%",
            }}
          />
        </Box>
      </Grid>
    ),
  },
];
