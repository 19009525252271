export interface GeomapChartProps {
  data: { name: string; value: number; iso2?: string }[];
  height?: string;
  showTooltip?: boolean;
  showLegend?: boolean;
  focusCountry?: string;
  zoom?: number;
}

export const GeomapSampleData = [
  {
    full_name: "Federal Republic of Nigeria",
    name: "Nigeria",
    iso2: "NG",
    value: 80000000,
  },
  {
    full_name: "United States of America",
    name: "United States",
    iso2: "US",
    value: 300000000,
  },
  {
    full_name: "Republic of Kenya",
    name: "Kenya",
    iso2: "KE",
    value: 121000000,
  },
  {
    full_name: "Federative Republic of Brazil",
    name: "Brazil",
    iso2: "BR",
    value: 90000000,
  },
  {
    full_name: "Republic of South Africa",
    name: "South Africa",
    iso2: "ZA",
    value: 60000000,
  },
  {
    full_name: "Russian Federation",
    name: "Russia",
    iso2: "RU",
    value: 150000000,
  },
  {
    full_name: "People's Republic of China",
    name: "China",
    iso2: "CN",
    value: 200000000,
  },
  {
    full_name: "Republic of India",
    name: "India",
    iso2: "IN",
    value: 180000000,
  },
  {
    full_name: "Federal Republic of Germany",
    name: "Germany",
    iso2: "DE",
    value: 120000000,
  },
  {
    full_name: "Commonwealth of Australia",
    name: "Australia",
    iso2: "AU",
    value: 70000000,
  },
  {
    full_name: "Canada",
    name: "Canada",
    iso2: "CA",
    value: 100000000,
  },
  {
    full_name: "United Mexican States",
    name: "Mexico",
    iso2: "MX",
    value: 50000000,
  },
  {
    full_name: "United Kingdom of Great Britain and Northern Ireland",
    name: "United Kingdom",
    iso2: "GB",
    value: 110000000,
  },
  {
    full_name: "Federal Democratic Republic of Ethiopia",
    name: "Ethiopia",
    iso2: "ET",
    value: 40000000,
  },
  {
    full_name: "Arab Republic of Egypt",
    name: "Egypt",
    iso2: "EG",
    value: 45000000,
  },
  {
    full_name: "Argentine Republic",
    name: "Argentina",
    iso2: "AR",
    value: 65000000,
  },
  {
    full_name: "Afghanistan",
    name: "Afghanistan",
    iso2: "AF",
    value: 65000000,
  },
];
