import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import { colors } from "app/theme";
import { ReactComponent as ShareButtonIcon } from "app/assets/vectors/ShareIcon.svg";
import React from "react";
import { SuccessIcon } from "app/assets/vectors/jsx/SuccessIcon";
import { LinkedInIcon } from "app/assets/vectors/jsx/LinkedIn";
import { ShareIcon } from "app/assets/vectors/jsx/ShareIcon";

const ButtonStyle = {
  display: "flex",
  alignItems: "center",
  columnGap: "5px",
  padding: "7px 10px",
};

export const ShareButton = (props: { id: string }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [shared, setShared] = React.useState<boolean>(false);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShared(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? props.id : undefined;

  return (
    <Box>
      <Button variant="general" sx={ButtonStyle} onClick={handleOpen}>
        <ShareButtonIcon />
        Share
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        //   keepMounted
        sx={{
          zIndex: 3,
          ".MuiPopover-paper": {
            background: "transparent",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "5px",
            border: `1px solid ${colors.secondary.iconGray}`,
            background: colors.secondary.lightGrey,
            padding: "16px",
            width: "272px",
            marginTop: "13px",
            "> input": {
              width: "100%",
              padding: "10px 20px",
              borderRadius: "5px",
              border: `1px solid ${colors.secondary.iconGray}`,
              background: colors.secondary.lightGrey,
              fontSize: "12px",
              fontFamily: "Inter",
              outline: "none",
            },
          }}
        >
          <Typography variant="button" lineHeight={"20px"}>
            Share
          </Typography>
          <Box height={16} />
          {shared ? (
            <Typography
              variant="body2"
              fontSize={"12px"}
              sx={{
                display: "flex",
                columnGap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              You've Successfully Shared! <SuccessIcon />
            </Typography>
          ) : (
            <>
              <Typography
                variant="body2"
                fontSize={"12px"}
                display={"block"}
                lineHeight={"normal"}
              >
                Emails*
              </Typography>
              <Box height={16} />
              <input type="text" placeholder="Jane.Doe@gmail.com" />

              <Box height={16} />
              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  width={29}
                  sx={{
                    borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
                  }}
                />
                <Typography variant="body2" fontSize={"12px"}>
                  Or Share the Website Via
                </Typography>
                <Box
                  width={29}
                  sx={{
                    borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
                  }}
                />
              </Grid>
              <Box height={16} />

              <Grid
                display={"flex"}
                columnGap={"14px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <LinkedInIcon /> <ShareIcon />
              </Grid>
            </>
          )}

          <Box height={16} />
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              variant="general"
              onClick={() => {
                if (shared) {
                  handleClose();
                }
                setShared(true);
              }}
            >
              {shared ? "Close" : "Share"}
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
