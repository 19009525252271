import React from "react";

export const CtaActivityIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="70"
      viewBox="0 0 64 70"
      fill="none"
      {...props}
    >
      <path
        d="M41.4364 0.509654C41.4364 0.509654 45.6802 2.13275 53.4802 9.93275C61.2516 17.7041 62.8812 21.9553 62.893 21.9863C63.0825 25.6896 63.2 30.0203 63.2 35.0661C63.2 50.1209 62.1539 58.8108 61.2732 63.4593C60.7075 66.4455 58.3773 68.4922 55.3555 68.8182C51.2766 69.2583 44.0787 69.7327 32 69.7327C19.9213 69.7327 12.7234 69.2583 8.64455 68.8182C5.62271 68.4922 3.29259 66.4455 2.72679 63.4593C1.84607 58.8108 0.800003 50.1209 0.800003 35.0661C0.800003 20.0112 1.84607 11.3213 2.72679 6.67294C3.29259 3.68673 5.62271 1.63996 8.64455 1.31389C12.7234 0.87374 19.9213 0.399414 32 0.399414C35.5528 0.399414 38.6834 0.440512 41.4364 0.509654Z"
        fill="#294C94"
      />
      <path
        d="M14.6667 50.6664C14.6667 52.581 16.2187 54.133 18.1333 54.133H42.4C44.3146 54.133 45.8667 52.581 45.8667 50.6664C45.8667 48.7517 44.3146 47.1997 42.4 47.1997H18.1333C16.2187 47.1997 14.6667 48.7517 14.6667 50.6664Z"
        fill="#182B54"
      />
      <path
        d="M14.6667 35.0663C14.6667 36.9809 16.2187 38.5329 18.1333 38.5329H32C33.9146 38.5329 35.4667 36.9809 35.4667 35.0663C35.4667 33.1516 33.9146 31.5996 32 31.5996H18.1333C16.2187 31.5996 14.6667 33.1516 14.6667 35.0663Z"
        fill="#182B54"
      />
      <path
        d="M62.8931 21.9865C57.9019 23.2419 52.0089 23.3253 46.919 21.7703C44.7671 21.1129 43.0892 19.4366 42.3629 17.307C40.5537 12.0018 40.1067 5.50811 41.4365 0.509766C41.4365 0.509766 45.68 2.13286 53.48 9.93286C61.28 17.7329 62.8931 21.9865 62.8931 21.9865Z"
        fill="#182B54"
      />
    </svg>
  );
};
