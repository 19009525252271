import React from "react";
import { ChartPlaceholder, InsightsBlockProps } from "./data";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { colors } from "app/theme";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import {
  LineChartSampleData,
  SignalLineChartSampleData,
} from "app/components/charts/line/data";
import { LineChart } from "app/components/charts/line";
import { SDGChart } from "app/components/charts/sdg";
import { SDGSampleData, SDGSampleData2 } from "app/components/charts/sdg/data";
import { NetworkChart } from "app/components/charts/network";
import { NetworkSampleData } from "app/components/charts/network/data";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { BarChart } from "app/components/charts/bar";
import { BarChartStackedSampleData } from "app/components/charts/bar/data";
import { PieChart } from "app/components/charts/pie";
import { PieChartSampleData } from "app/components/charts/pie/data";
import { ChartsBlock } from "app/components/grid-blocks/charts";
import { ArrowFall, ArrowRise } from "app/assets/vectors/jsx/arrow";
import { ImpactChart } from "app/components/charts/impact";
import { IMPACT_CHART_ITEMS_EXTRA } from "app/components/charts/impact/data";
import { CtaFavouriteIcon } from "app/assets/vectors/jsx/CtaFavouriteIcon";
import { CtaPublisherIcon } from "app/assets/vectors/jsx/CtaPublisherIcon";
import { CtaLocationIcon } from "app/assets/vectors/jsx/CtaLocationIcon";
import { CtaOrganisationIcon } from "app/assets/vectors/jsx/CtaOrganisationIcon";
import { CtaSectorIcon } from "app/assets/vectors/jsx/CtaSectorIcon";
import { CtaActivityIcon } from "app/assets/vectors/jsx/CtaActivityIcon";
import { useParams } from "react-router-dom";
import { SAMPLE_LOCATIONS_DATA } from "app/components/generic-table/data";
import { GeomapChart } from "app/components/charts/geomap";
import { GeomapSampleData } from "app/components/charts/geomap/data";

export const InsightsBlock: React.FC<InsightsBlockProps> = (
  props: InsightsBlockProps
) => {
  const { id } = useParams();

  const location = SAMPLE_LOCATIONS_DATA.find(
    (location) => location.code.toLowerCase() === id?.toLowerCase()
  );

  const overview = React.useMemo(() => {
    const items = [
      { name: "Disbursements and Expenditures", value: "USD 226 billion" },
      { name: "Activities", value: "1098" },
      { name: "Planned Activities", value: "0" },
      { name: "Organisations", value: "145" },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        title="Overview"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD 226 billion
            </span>{" "}
            was disbursed to {location?.location} in 2022.
          </React.Fragment>
        }
        content={<OverviewBlock items={items} />}
      />
    );
  }, [location]);

  const geospatialData = React.useMemo(() => {
    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          title="Geospatial Data"
          onExpand={() => props.setExpandedBlock("geospatial-data")}
          description={
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                30% of the activities
              </span>{" "}
              have provided exact locations.
            </React.Fragment>
          }
          content={
            <GeomapChart
              data={GeomapSampleData}
              zoom={8}
              focusCountry={location?.location}
              height={
                props.expandedBlock === "geospatial-data" ? "571px" : "319px"
              }
            />
          }
          expanded={props.expandedBlock === "geospatial-data"}
        />
      </React.Fragment>
    );
  }, [props.expandedBlock, location]);

  const results = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Results"
        onExpand={() => props.setExpandedBlock("results")}
        height="100%"
        description={
          <React.Fragment>
            In 2022, available results show activities surpassed their target
            results by{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              4.8%
            </span>
            on average.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              Actual results and their relation to the target results within the
              last five years:
            </Typography>

            <LineChart
              id="results-line"
              data={LineChartSampleData}
              yAxisLabelName="% of success"
              height="376px"
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "results"}
      />
    );
  }, [props.expandedBlock]);

  const sdgs = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sustainable Development Goals"
        onExpand={() => props.setExpandedBlock("sdgs")}
        description={
          <React.Fragment>
            In 2022,{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              125,000 activities
            </span>{" "}
            were contributing to the SDGs.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              There was a 8% increase in 2022 in the number of organisations
              publishing data on SDGs, compared to 2021. <br />
              <br />
              Top six trending Sustainable Development Goals of 2022 in{" "}
              {location?.location}:
            </Typography>
            <Box height={28} />
            <SDGChart
              data={
                props.expandedBlock === "sdgs" ? SDGSampleData : SDGSampleData2
              }
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sdgs"}
      />
    );
  }, [props.expandedBlock, location]);

  const networks = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        onExpand={() => props.setExpandedBlock("networks")}
        height="100%"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              1528 organisations
            </span>{" "}
            have published data on their development and humanitarian resources
            and activities.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              See the organisation network by their sectors:
            </Typography>{" "}
            <NetworkChart data={NetworkSampleData} />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "networks"}
      />
    );
  }, [props.expandedBlock]);

  const budgetLines = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Budget Lines"
        onExpand={() => props.setExpandedBlock("budgetLines")}
        height="100%"
        description={
          <React.Fragment>
            Budget lines by sectors show that{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              Productive Sectors
            </span>{" "}
            were the most funded sectors of 2022.
          </React.Fragment>
        }
        content={
          <BarChart
            barWidth="40"
            yAxisLabelName="USD"
            xAxisLabelRotate={0}
            data={BarChartStackedSampleData}
          />
        }
        expanded={props.expandedBlock === "budgetLines"}
      />
    );
  }, [props.expandedBlock]);

  const organisations = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Organisations"
        onExpand={() => props.setExpandedBlock("organisations")}
        description={
          <React.Fragment>
            Top 10 organisations provides in total of{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              678,2 million development aid
            </span>{" "}
            in 2022. Which makes up the{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              85% of the total development aid.
            </span>
            were the most funded sectors of 2022.
          </React.Fragment>
        }
        content={
          <PieChart
            id="organisations-pie"
            donut
            half
            data={PieChartSampleData}
            unit="USD"
            height={props.expandedBlock === "organisations" ? "500px" : "350px"}
          />
        }
        expanded={props.expandedBlock === "organisations"}
      />
    );
  }, [props.expandedBlock]);

  const sectors = React.useMemo(() => {
    const Legend = (props: {
      percentageChange: number;
      year: number;
      value: string;
      name: string;
    }) => {
      return (
        <Box>
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {props.name}
          </Typography>
          <Box height={20} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                color={colors.secondary.blue}
                lineHeight={"normal"}
              >
                {props.value}
              </Typography>

              <Typography
                variant="body2"
                fontSize={"12px"}
                lineHeight={"normal"}
              >
                {props.year}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <Typography
                color={
                  props.percentageChange < 0 ? colors.secondary.red : "#2EA390"
                }
                variant="button"
              >
                {props.percentageChange > 0 ? "+" : null}
                {props.percentageChange}
              </Typography>

              {props.percentageChange < 0 ? <ArrowFall /> : <ArrowRise />}
            </Box>
          </Box>
        </Box>
      );
    };

    const items = [
      {
        name: "Disaster Prevention and Preparedness",
        value: "180K",
        percentageChange: 21.01,
        year: 2023,
      },
      {
        name: "Development Food Assistance",
        value: "250K",
        percentageChange: -17.34,
        year: 2023,
      },
      {
        name: "Energy policy and administrative management",
        value: "107K",
        percentageChange: -12.34,
        year: 2023,
      },
      {
        name: "Environmental policy and administrative management",
        value: "309K",
        percentageChange: 11.01,
        year: 2023,
      },

      {
        name: "Disaster Prevention and Preparedness",
        value: "180K",
        percentageChange: 21.01,
        year: 2023,
      },
      {
        name: "Development Food Assistance",
        value: "250K",
        percentageChange: -17.34,
        year: 2023,
      },
      {
        name: "Energy policy and administrative management",
        value: "107K",
        percentageChange: -12.34,
        year: 2023,
      },
      {
        name: "Environmental policy and administrative management",
        value: "309K",
        percentageChange: 11.01,
        year: 2023,
      },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sectors"
        onExpand={() => props.setExpandedBlock("sectors")}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              Disaster Prevention and Preparedness
            </span>{" "}
            was the sector to have the highest percentage of growth for 2022.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              Sectors with significant funding rate changes for 2022:
            </Typography>
            <Box height={20} />
            <ChartsBlock
              items={items
                .slice(0, props.expandedBlock === "sectors" ? 8 : 4)
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <LineChart
                      id={`sector-${index}`}
                      data={SignalLineChartSampleData}
                      yAxisLabelName="USD"
                      variant="signalLine"
                      height="250px"
                    />
                    <Box height={10} />
                    <Legend {...item} />
                  </React.Fragment>
                ))}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sectors"}
      />
    );
  }, [props.expandedBlock]);

  const impact = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Impact"
        onExpand={() => props.setExpandedBlock("impact")}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              Over 12,000 activities
            </span>{" "}
            went above and beyond their target results in 2022, showcasing
            noteworthy achievements and positive change.
          </React.Fragment>
        }
        content={<ImpactChart items={[...IMPACT_CHART_ITEMS_EXTRA]} />}
        expanded={props.expandedBlock === "impact"}
      />
    );
  }, [props.expandedBlock]);

  const expandedBlockView = React.useMemo(() => {
    switch (props.expandedBlock) {
      case "geospatial-data":
        return geospatialData;
      case "results":
        return results;
      case "sdgs":
        return sdgs;
      case "networks":
        return networks;
      case "budgetLines":
        return budgetLines;
      case "organisations":
        return organisations;
      case "sectors":
        return sectors;
      default:
        return null;
    }
  }, [
    props.expandedBlock,
    geospatialData,
    results,
    sdgs,
    networks,
    budgetLines,
    organisations,
    sectors,
  ]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const geospacialDataBlockHeight = React.useMemo(
    () => getBlockHeight("block-Geospatial Data"),
    [geospatialData]
  );
  const resultsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Results"),
    [results]
  );

  const sdgsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sustainable Development Goals"),
    [sdgs]
  );

  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );
  const budgetLinesBlockHeight = React.useMemo(
    () => getBlockHeight("block-Budget Lines"),
    [budgetLines]
  );

  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Organisations"),
    [organisations]
  );

  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sectors"),
    [sectors]
  );

  const impactBlockHeight = React.useMemo(
    () => getBlockHeight("block-Impact"),
    [impact]
  );

  return (
    <Grid container spacing={4}>
      {props.expandedBlock && (
        <Grid item xs={12}>
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} md={7}>
        {overview}
        <Box sx={{ height: 20 }} />
        {props.expandedBlock !== "geospacial-data" ? (
          geospatialData
        ) : (
          <ChartPlaceholder height={geospacialDataBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5}>
        {props.expandedBlock !== "results" ? (
          results
        ) : (
          <ChartPlaceholder height={resultsBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5.5}>
        {props.expandedBlock !== "sdgs" ? (
          sdgs
        ) : (
          <ChartPlaceholder height={sdgsBlockHeight} />
        )}
        <Box sx={{ height: 20 }} />
        <Box
          sx={{
            gap: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CtaCommonBtn
            label="Your Favourites"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaFavouriteIcon />}
          />
          <CtaCommonBtn
            label="Publish Your Data"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaPublisherIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6.5}>
        {props.expandedBlock !== "networks" ? (
          networks
        ) : (
          <ChartPlaceholder height={networksBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5}>
        {props.expandedBlock !== "budgetLines" ? (
          budgetLines
        ) : (
          <ChartPlaceholder height={budgetLinesBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={7}>
        {props.expandedBlock !== "organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}

        <Box
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
          }}
        >
          <CtaCommonBtn
            label="Discover Locations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaLocationIcon />}
          />
          <CtaCommonBtn
            label="Discover Organisations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaOrganisationIcon />}
          />
          <CtaCommonBtn
            label="Discover Sectors"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaSectorIcon />}
          />
          <CtaCommonBtn
            label="Search & Filter Activities"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaActivityIcon />}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "impact" ? (
          impact
        ) : (
          <ChartPlaceholder height={impactBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
