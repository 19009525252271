import { GenericTableColumn } from "app/components/generic-table/data";
import { colors } from "app/theme";
import { AIDAArrowForward } from "app/assets/vectors/jsx/arrow";
import { Box } from "@mui/material";

export const ORGANISATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "name",
    headerName: "Organisation Name",
    align: "left",
    headerAlign: "left",
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "iatiIdentifier",
    headerName: "IATI Organisation Identifier",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Organisation Name",
  },
  {
    field: "organisationType",
    headerName: "Organisation Type",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Organisation Name",
  },
  {
    field: "organisationHQ",
    headerName: "Organisation HQ",
    width: 150,
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Organisation Name",
  },
  {
    field: "totalDatasets",
    headerName: "Total Datasets",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Organisation Name",
  },
  {
    field: "totalActivities",
    headerName: "Total Activities",
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Organisation Name",
  },
  {
    field: "",
    headerName: "",
    headerAlign: "right",
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
        <AIDAArrowForward />
      </Box>
    ),
  },
];
