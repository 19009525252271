import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { NetworkChart } from "app/components/charts/network";
import { SECTORS } from "app/pages/explorer/pages/sectors/data";
import { NetworkSampleData } from "app/components/charts/network/data";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { SECTOR_COLUMNS } from "app/pages/explorer/pages/sectors/tableColumns";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerOrganisationsHeaderIcon.svg";

export const ExplorerSectors = () => {
  const [activeOption, setActiveOption] = React.useState("");
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Faces Behind Impact: Explore Organizations"
        subtitle="Get ready to dive into the heart of humanitarian aid with AIDA's organisation-specific data. This feature invites you to explore a network of dedicated organizations making a real difference in the world."
        icon={<ExplorerOrganisationsHeaderIcon />}
      />
      <Box height={40} />
      <Typography variant="h2" fontSize={"36px"} lineHeight={"normal"}>
        Explore the Sector of the Month
      </Typography>
      <Box height={40} />

      <ExploreListBlockItem
        title="SECTOR OF THE MONTH"
        description={"Emergency Response"}
        leftItems={[
          {
            title: "Overview",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  USD 226 billion
                </span>{" "}
                was spent for
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  218 recipient countries{" "}
                </span>{" "}
                in 2023.
              </React.Fragment>
            ),
          },
          {
            title: "Locations",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  Somalia, Yemen, South Sudan{" "}
                </span>
                and{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  Afghanistan
                </span>{" "}
                were the largest recipient countries of 2022.
              </React.Fragment>
            ),
          },
          {
            title: "Results",
            description: (
              <React.Fragment>
                In 2022, available results show activities surpassed about{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  4.8%
                </span>{" "}
                of their target results on average.
              </React.Fragment>
            ),
          },
          {
            title: "SUSTAINABLE DEVELOPMENT GOALS",
            description: (
              <React.Fragment>
                In 2022,{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  125,000 activities{" "}
                </span>
                were contributing to the SDGs.
              </React.Fragment>
            ),
          },

          {
            title: "FUNDING Analysis",
            description: (
              <React.Fragment>
                Funding for Emergency Response sector has been
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  increased 1.4%{" "}
                </span>
                in 2022 compared to 2021 data.
              </React.Fragment>
            ),
          },
          {
            title: "ORGANISATIONS",
            description: (
              <React.Fragment>
                Top 10 organisations provides in total of
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  678,2 million{" "}
                </span>
                development aid in 2022. Which makes up the{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  85% of the total development aid.
                </span>
              </React.Fragment>
            ),
          },
        ]}
        rightContent={{
          title: "NETWORKS",
          description: (
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                1528 organisations
              </span>{" "}
              have published data on their development and humanitarian
              resources and activities.
            </React.Fragment>
          ),
          content: (
            <NetworkChart height="319px" data={NetworkSampleData} zoom={3} />
          ),
        }}
        buttonText="See the Sector Page"
        buttonLink="/explorer/sectors/720"
      />
      <Box height={40} />
      <Typography variant="h2" fontSize={"36px"} lineHeight={"normal"}>
        Explore All Sectors & Search
      </Typography>
      <Box height={40} />

      <ExploreListAllBlock
        extraOptions={["DAC 3 Digit Sectors", "DAC 5 Digit Sectors"]}
        setActiveExtraOption={setActiveOption}
        activeExtraOption={activeOption}
        columns={SECTOR_COLUMNS}
        rows={SECTORS}
      />
    </Box>
  );
};
