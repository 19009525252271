import React from "react";
import Box from "@mui/material/Box";
import GeneralChartPlaceholder from "app/assets/vectors/GeneralChartPlaceholder.svg";

export interface InsightsBlockProps {
  expandedBlock: string | null;
  setExpandedBlock: (
    value:
      | "locations"
      | "results"
      | "sdgs"
      | "network"
      | "impact"
      | "budgetLines"
      | null
  ) => void;
}

export const ChartPlaceholder: React.FC<{
  width?: string;
  height?: string;
}> = (props: { width?: string; height?: string }) => {
  return (
    <Box
      sx={{
        width: props.width ?? "100%",
        height: props.height ?? "400px",
        backgroundImage: `url(${GeneralChartPlaceholder})`,
      }}
    />
  );
};
