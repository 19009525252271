import React from "react";

export const CtaLocationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      {...props}
    >
      <path
        d="M67.192 35.1151C67.192 35.1151 69.2181 49.0263 66.1091 54.336C59.8855 64.973 48.4104 72.0761 35.3226 71.9994C15.7025 71.8873 -0.11574 55.6752 0.000699768 35.7935C0.0298096 30.9677 0.990436 26.3719 2.71374 22.1773C4.49526 17.8352 13.3039 15.1331 13.3039 15.1331C13.3039 15.1331 16.244 5.4991 19.7546 3.73512C24.5636 1.31629 29.9955 -0.0347191 35.7301 0.000678491C42.1168 0.0360761 48.1076 1.78236 53.2717 4.80295C55.0882 5.86488 57.1841 10.1126 57.1841 10.1126C57.1841 10.1126 60.7995 10.6436 61.8824 11.8589C64.1646 14.4193 66.0917 17.3101 67.5763 20.4487C69.841 25.2156 67.192 35.1151 67.192 35.1151Z"
        fill="#294C94"
      />
      <path
        d="M29.7349 11.8704C28.7161 12.4899 29.2692 14.1064 28.2562 14.6846C26.5154 15.6757 21.8695 12.6551 22.1373 10.3896C22.4342 7.94132 28.5414 5.98265 30.7538 7.87642C32.1802 9.09763 31.4175 11.4221 32.7973 12.0179C33.9675 12.5253 36.011 11.4988 36.4477 9.94718C37.024 7.89412 34.474 6.10064 34.2586 5.95315C31.6737 4.18917 28.1223 5.15081 26.8123 5.51068C23.9828 6.27763 23.3424 7.40445 21.9917 6.99148C20.5653 6.55491 20.2742 4.98562 19.7561 3.729C12.106 7.57554 6.01626 14.1359 2.71521 22.1711C3.9844 23.9941 7.07586 24.2124 8.11217 27.41C8.65943 29.0914 8.27518 30.4896 9.42793 31.404C10.5981 32.3361 11.8499 31.5633 12.7872 32.5898C13.3461 33.1975 13.1249 33.7107 13.515 35.8464C13.8934 37.9112 14.0855 38.9378 14.6852 39.5454C16.4085 41.2917 20.6527 39.9584 20.6701 39.2505C20.6876 38.7844 18.842 38.9201 18.19 37.6222C17.5088 36.2653 18.9643 35.0263 18.3355 33.7756C17.6427 32.401 15.477 33.0736 14.8307 31.8524C14.1612 30.5899 15.4362 27.9173 17.4622 26.82C20.4314 25.2094 22.5448 28.3185 25.4965 27.115C29.1702 25.6165 28.1048 19.8998 32.5062 17.7936C34.7244 16.7317 36.436 17.4987 36.7388 16.6078C37.3268 14.9264 31.7493 10.6374 29.7349 11.8704Z"
        fill="#182B54"
      />
      <path
        d="M25.5785 36.9696C27.948 36.0315 30.1371 36.5684 30.9871 36.7808C31.6566 36.946 33.2577 37.359 34.7889 38.6392C37.1817 40.6391 36.4772 42.291 38.4974 43.6479C40.6749 45.111 43.0677 44.232 43.4403 45.3706C43.6441 45.9842 42.9454 46.2673 42.3574 47.7186C41.3153 50.2909 42.3807 52.1433 41.2745 52.8808C40.669 53.282 39.4231 53.3528 39.4231 53.3528C39.4231 53.3528 39.5338 54.8631 39.1146 56.0135C38.8235 56.8217 38.5033 56.7922 37.7231 57.8896C36.6286 59.4235 36.9313 59.919 36.1337 60.9279C34.9402 62.4381 33.8049 61.9249 33.0888 62.9514C31.5809 65.1166 35.47 69.0988 34.3231 70.4616C33.4556 71.494 29.9042 70.7861 28.3498 68.5855C27.2552 67.0339 28.0645 66.0369 27.4241 62.2199C26.9001 59.0872 25.7764 56.3262 25.3631 55.335C24.187 52.4914 23.3021 51.4354 23.634 49.6183C23.9134 48.0549 24.7518 47.8248 24.647 46.6744C24.4374 44.4916 21.2994 44.3028 20.9384 42.291C20.5891 40.2734 23.2788 37.8781 25.5785 36.9696Z"
        fill="#182B54"
      />
      <path
        d="M67.5834 20.4464C62.6347 19.2429 61.3539 18.883 59.1997 19.4789C57.3425 19.9922 55.8463 20.9597 52.8596 22.8889C50.0127 24.7295 49.512 25.5142 49.2908 25.8976C48.0623 28.0864 48.6503 30.5111 49.2908 33.1246C49.8497 35.4078 50.4551 37.8974 52.062 38.3458C52.8305 38.5582 53.238 38.1393 55.6309 37.1423C58.6816 35.868 60.2069 35.2308 60.7833 35.7382C61.9302 36.747 58.2973 40.6702 59.7936 43.5669C60.795 45.5079 63.147 45.1539 63.9563 47.384C64.5036 48.9002 63.6244 49.6435 63.9563 51.201C64.2125 52.3986 65.0683 53.5018 66.1104 54.3337C69.2193 49.0182 71.0183 42.8295 71.0591 36.2042C71.094 30.5642 69.8423 25.2133 67.5834 20.4464Z"
        fill="#182B54"
      />
      <path
        d="M61.8858 11.8552C59.4056 9.07652 56.5005 6.68719 53.2751 4.79932C53.0481 5.82585 53.0655 6.82878 53.4556 7.63112C54.0029 8.75205 55.1381 9.19451 55.0392 10.2387C54.9402 11.2712 53.7176 12.1384 52.6638 12.4452C50.8648 12.9762 49.7237 11.8493 49.0949 12.4452C48.1576 13.3419 50.2593 16.333 49.2929 17.2652C48.6641 17.8728 47.523 16.8227 46.3237 17.4657C45.1884 18.0793 44.449 19.9672 45.136 21.2828C45.6716 22.3034 46.9292 22.7164 47.9073 22.6869C48.7281 22.6633 49.8285 22.3152 52.8618 18.8699C55.6621 15.69 56.0638 14.4629 57.2224 14.4511C59.1669 14.4334 60.6166 17.8728 60.9892 17.6604C61.3327 17.4658 59.3824 14.8994 59.9995 13.2416C60.2498 12.5573 60.9368 12.1207 61.8858 11.8552Z"
        fill="#182B54"
      />
    </svg>
  );
};
