import { Box, Divider, Grid } from "@mui/material";
import React from "react";

interface ChartsBlockProps {
  items: React.ReactNode[];
}

export const ChartsBlock: React.FC<ChartsBlockProps> = (
  props: ChartsBlockProps
) => {
  return (
    <Grid
      container
      spacing={2}
      rowGap={2}
      sx={{
        // paddingTop: "16px",

        h4: {
          fontSize: "18px",
          fontWeight: "700",
        },
        "> div": {
          gap: "10px",
          display: "flex",
          paddingBottom: "16px",
          paddingRight: "16px",
          flexDirection: "column",
          "&:not(:nth-of-type(4n+0))": {
            borderRight: "1px solid #E0E0E0",
          },
        },
      }}
    >
      {props.items.map((item) => (
        <Grid item sm={12} md={3}>
          <Box>{item}</Box>
        </Grid>
      ))}
    </Grid>
  );
};
