export interface BarLineChartProps {
  barWidth?: string;
  xAxisLabelRotate?: number;
  yAxisLabelName: string;
  data: {
    bars: string[];
    series: { name: string; type: "line" | "bar" }[];
    values: number[][];
  };
  showLegend?: boolean;
  id: string;
  height?: string;
}

export const BarLineChartSampleData: BarLineChartProps["data"] = {
  bars: [
    "Post-Secondary\n Education",
    "Health, General",
    "Basic Health",
    "Non-communicable\n diseases (NCDs)",
    "Water Supply\n & Sanitation",
  ],
  series: [
    { name: "Productive Sectors", type: "bar" },
    { name: "Estimated Funds to be Allocated", type: "line" },
  ],
  values: [
    [600000, 800000, 750000, 350000, 800000], // bar
    [600000, 800000, 800000, 400000, 800000], // line
  ],
};
