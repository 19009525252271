import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { PageHeader } from "app/components/page-header";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { YourDataHub } from "app/pages/publisher/components/your-data-hub";
import { PublisherBlock } from "app/pages/publisher/components/publisher-block";
import { YourFavourites } from "app/pages/publisher/components/your-favourites";
import { ReactComponent as PublisherHeaderIcon } from "app/assets/vectors/PublisherHeader.svg";
import { ReactComponent as PublisherAIDAExplorer } from "app/assets/vectors/PublisherAIDAExplorer.svg";

export const Publisher: React.FC = () => {
  return (
    <Box
      sx={{
        gap: "30px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageHeader
        variant="publisher"
        title="AIDA Publisher: Publish, Validate, Visualise Data"
        subtitle="Ready to share your impact with the world? Publish your data to contribute to a global network of meaningful insights."
        icon={<PublisherHeaderIcon />}
      />
      <PublisherBlock />
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={6}
          lg={3}
          sx={{
            "> button": {
              width: "100%",
            },
          }}
        >
          <CtaCommonBtn
            bgColor="green"
            variant="medium"
            label="AIDA Explorer"
            bgIcon={<PublisherAIDAExplorer />}
          />
          <Box sx={{ height: "30px" }} />
          <YourFavourites activeItems={0} plannedItems={0} />
        </Grid>
        <Grid item xs={12} md={6} lg={9}>
          <YourDataHub />
        </Grid>
      </Grid>
    </Box>
  );
};
