import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { GraphSeriesOption, GraphChart as EChartsGraph } from "echarts/charts";
import {
  NetworkChartProps,
  NetworkSampleData,
} from "app/components/charts/network/data";
import { VisualMapComponent } from "echarts/components";

echarts.use([EChartsGraph, SVGRenderer, VisualMapComponent]);

export const NetworkChart: React.FC<NetworkChartProps> = (
  props: NetworkChartProps
) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: "network-chart",
  });

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<GraphSeriesOption> = {
        color: colors.charts,
        series: [
          {
            name: "Network",
            type: "graph",
            layout: "force",
            data:
              props.data.nodes.length > 0
                ? props.data.nodes
                : NetworkSampleData.nodes,
            links:
              props.data.links.length > 0
                ? props.data.links
                : NetworkSampleData.links,
            color:
              props.data.nodes.length > 0 && props.data.links.length > 0
                ? undefined
                : colors.secondary.iconGray,
            categories: props.data.categories,
            zoom: props.zoom ?? 4,
            emphasis: {
              focus: "none",
              label: {
                show: false,
              },
            },
            force: {
              repulsion: 70,
              edgeLength: 50,
              layoutAnimation: false,
            },
            lineStyle: {
              width: 2,
              curveness: 0.3,
              color: "source",
            },
            label: {
              show: false,
            },
          },
        ],
      };

      chart.setOption(option);
      setStateChart(chart);
    }
  }, [containerRef.current, props.data]);

  return (
    <Box
      id="network-chart"
      ref={containerRef}
      width="100%"
      height={props.height ?? "450px"}
    />
  );
};
