import { Box, ClickAwayListener } from "@mui/material";
import React from "react";
import { ReactComponent as ExplorerSearchIcon } from "app/assets/vectors/ExplorerSearchIcon.svg";
import { colors } from "app/theme";
import { ExplorerSearchResultsPopup } from "./searchResultsPopup";
import { SearchInput } from "app/components/search-input";

export const ExplorerSearch = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
  const [searchValue, setSearchValue] = React.useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>
  ) => {
    if (e.target.value.length > 2) {
      setAnchorEl(e.currentTarget);
    } else {
      handleClose();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleOpen(e);
    setSearchValue(e.target.value);
  };

  const id = "explorer-search-input";

  return (
    <div>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <SearchInput
            type="text"
            placeholder="Search"
            aria-describedby={id}
            onChange={handleChange}
            value={searchValue}
            onFocus={handleOpen}
          />
          <ExplorerSearchResultsPopup
            anchorEl={anchorEl}
            id={id}
            handleClose={handleClose}
          />
        </Box>
      </ClickAwayListener>
    </div>
  );
};
