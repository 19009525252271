import React from "react";

export const BallTriangleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      {...props}
    >
      <path
        d="M13.0276 5.75065C13.418 7.39129 12.4045 9.03776 10.7639 9.42818C9.12323 9.8186 7.47676 8.80511 7.08635 7.16447C6.69593 5.52384 7.70942 3.87736 9.35006 3.48694C10.9907 3.09652 12.6372 4.11003 13.0276 5.75065Z"
        fill="#B6B6B6"
      />
      <path
        d="M8.92784 11.0705C9.31826 12.7111 8.30477 14.3576 6.66413 14.748C5.02349 15.1384 3.37702 14.1249 2.9866 12.4843C2.59619 10.8437 3.60967 9.19718 5.25031 8.80677C6.89095 8.41635 8.53743 9.42985 8.92784 11.0705Z"
        fill="#B6B6B6"
      />
      <path
        d="M15.7678 11.8307C16.1582 13.4714 15.1447 15.1178 13.5041 15.5083C11.8634 15.8987 10.217 14.8852 9.82654 13.2445C9.43612 11.6039 10.4496 9.95744 12.0902 9.56702C13.7309 9.1766 15.3774 10.1901 15.7678 11.8307Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};
