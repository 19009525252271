import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { ReactComponent as FreePlanBannerIcon } from "app/assets/vectors/DataHubFreePlanBanner.svg";

interface FreePlanBannerProps {
  setShowFreePlanBanner: (value: boolean) => void;
}

export const FreePlanBanner: React.FC<FreePlanBannerProps> = (
  props: FreePlanBannerProps
) => {
  return (
    <Box
      sx={{
        gap: "40px",
        width: "100%",
        height: "195px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px 30px",
        background: colors.primary.green,
      }}
    >
      <FreePlanBannerIcon />
      <Box
        sx={{
          flex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={() => props.setShowFreePlanBanner(false)}>
            <Close htmlColor={colors.primary.white} />
          </IconButton>
        </Box>
        <Box>
          <Typography variant="h3" fontSize="24px" color={colors.primary.white}>
            Upgrade your plan to continue publishing your files and create
            impact.
          </Typography>
          <Typography variant="h5" fontSize="14px" color={colors.primary.white}>
            For unlimited data uploads and automation, sign up for our standard
            & premium plans.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            endIcon={<ArrowForward />}
            sx={{
              padding: "4px 10px",
              color: colors.primary.green,
              background: colors.primary.white,
              "&:hover": {
                background: colors.primary.white,
              },
            }}
          >
            Upgrade Plan
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
