import React from "react";
import get from "lodash/get";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import { InfoIcon } from "app/assets/vectors/jsx/InfoIcon";
import { GenericTableProps } from "app/components/generic-table/data";

export const GenericTable: React.FC<GenericTableProps> = (
  props: GenericTableProps
) => {
  return (
    <TableContainer
      id={props.id}
      sx={{
        maxHeight: "500px",
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow sx={props.headerRowStyle ?? {}}>
            {props.columns.map((column) => (
              <TableCell
                align={column?.headerAlign ?? "left"}
                key={column.field}
                width={column.width}
              >
                <Typography
                  variant="body2"
                  fontSize={"12px"}
                  fontWeight={700}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    ...column.headerStyle,
                  }}
                >
                  {column.headerName}{" "}
                  {column.headerInfo && <InfoIcon width={14} height={14} />}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow key={index} sx={props.rowStyle}>
              {props.columns.map((column) => (
                <TableCell
                  align={column?.align ?? "left"}
                  key={column.field}
                  sx={{ lineHeight: "1.43" }}
                >
                  {column.renderCell ? (
                    column.renderCell({
                      row,
                      value: get(row, column.field),
                    })
                  ) : (
                    <Typography variant="body2" fontSize={"12px"}>
                      {get(row, column.field)}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
