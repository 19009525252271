import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CircleFlag } from "react-circle-flags";
import Typography from "@mui/material/Typography";

interface LocationsBlockProps {
  items: {
    code: string;
    name: string;
    funds: string;
    activities: number;
  }[];
}

export const LocationsBlock: React.FC<LocationsBlockProps> = (
  props: LocationsBlockProps
) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingTop: "16px",
        h6: {
          fontSize: "12px",
          fontWeight: "400",
        },
        h4: {
          fontSize: "18px",
          fontWeight: "700",
        },
        "> div": {
          gap: "10px",
          display: "flex",
          paddingBottom: "16px",
          flexDirection: "column",
          "&:not(:last-child)": {
            borderRight: "1px solid #E0E0E0",
          },
        },
      }}
    >
      {props.items.map((item) => (
        <Grid item sm={12} md={3}>
          <Box
            sx={{
              gap: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                gap: "8px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CircleFlag width="20" height="20" countryCode={item.code} />
              <Typography variant="h4">{item.name}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">Incoming Funds</Typography>
              <Typography variant="h4">{item.funds}</Typography>
            </Box>
            <Typography variant="h6">{item.activities} Activities</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
