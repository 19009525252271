import { Box, Grid, Typography } from "@mui/material";
import { get } from "lodash";
import { ReactComponent as ListIcon } from "app/assets/vectors/GenericListIcon.svg";
import { ReactComponent as ArrowRight } from "app/assets/vectors/TableArrowRight.svg";
import { GenericTableColumn } from "app/components/generic-table/data";
import { CircleFlag } from "react-circle-flags";

export const ACTIVITY_STATUS_COLORS = {
  "Pipeline/Identification": "#333ED4",
  Finalisation: "#30A235",
  Implementation: "#A0D736",
  Suspended: " #EEDE03",
  Closed: "#FD0200",
  Cancelled: "#F76915",
};

export const ACTIVITY_COLUMNS: GenericTableColumn[] = [
  {
    field: "name",
    headerName: "Activity Name",
    width: 675,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <Grid display={"flex"} alignItems={"center"} columnGap={"6px"}>
        <Box
          sx={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: get(ACTIVITY_STATUS_COLORS, params.value, "#333ED4"),
          }}
        />
        <Typography variant="body2" fontSize={"12px"}>
          {params.value}
        </Typography>
      </Grid>
    ),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "location",
    headerName: "Location(s)",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
];

export const LOCATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "code",
    headerName: "Code",
    width: 50,
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    align: "left",
    headerAlign: "left",
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"8px"}>
        <CircleFlag
          width={20}
          height={20}
          countryCode={params.row.code.toLowerCase()}
        />

        <Typography variant="body2" fontSize={"12px"}>
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "relatedContent",
    headerName: "Related Content",
    width: 726,
    renderCell: (params: any) => (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
          <ListIcon />
          <Typography variant="body2" fontSize={"12px"}>
            {params.value}
          </Typography>
        </Box>
        <ArrowRight />
      </Box>
    ),
  },
];

export const ORGANISATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "iatiId",
    headerName: "IATI ID",
    width: 120,
  },
  {
    field: "organisation",
    headerName: "Organisation",
    width: 250,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "relatedContent",
    headerName: "Related Content",
    width: 606,
    renderCell: (params: any) => (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
          <ListIcon />
          <Typography variant="body2" fontSize={"12px"}>
            {params.value}
          </Typography>
        </Box>
        <ArrowRight />
      </Box>
    ),
  },
];

export const SECTOR_COLUMNS: GenericTableColumn[] = [
  {
    field: "code",
    headerName: "Code",
    width: 70,
  },
  {
    field: "category",
    headerName: "Category",
    width: 70,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "sectorName",
    headerName: "Sector Name",
    width: 300,
  },
  {
    field: "relatedContent",
    headerName: "Related Content",
    width: 526,

    renderCell: (params: any) => (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
          <ListIcon />
          <Typography variant="body2" fontSize={"12px"}>
            {params.value}
          </Typography>
        </Box>
        <ArrowRight />
      </Box>
    ),
  },
];
