import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { ImpactChartItem } from "app/components/charts/impact/item";
import { ImpactChartProps } from "app/components/charts/impact/data";

export const ImpactChart: React.FC<ImpactChartProps> = (
  props: ImpactChartProps
) => {
  const grid = props.items.length > 2;
  return (
    <Box
      sx={{
        gap: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h6"
        fontSize="12px"
        fontWeight="400"
        marginBottom="10px"
      >
        Here's a selection of the most successful activities carried out and and
        resulted in 2022:
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: grid ? "grid" : "flex",
          flexDirection: "column",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        }}
      >
        {props.items.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              borderRight:
                grid && index % 2 === 0 ? "1px solid #A1AEBD" : "none",
              paddingRight: grid && index % 2 === 0 ? "20px" : "0",
              paddingLeft: grid && index % 2 === 1 ? "20px" : "0",
            }}
          >
            <ImpactChartItem {...item} />
            {index < props.items.length - (grid ? 2 : 1) && (
              <Divider
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  borderColor: colors.secondary.iconGray,
                }}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
