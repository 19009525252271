import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ProfilePicture from "app/assets/images/profile-picture.png";
import { ReactComponent as GoogleIcon } from "app/assets/vectors/HeaderSignInViewGoogle.svg";
import { ReactComponent as LogOutIcon } from "app/assets/vectors/HeaderSignInViewLogOut.svg";
import { ReactComponent as LinkedinIcon } from "app/assets/vectors/HeaderSignInViewLinkedin.svg";
import { ReactComponent as MicrosoftIcon } from "app/assets/vectors/HeaderSignInViewMicrosoft.svg";
import { ReactComponent as MyAccountIcon } from "app/assets/vectors/HeaderSignInViewMyAccount.svg";
import { HeaderViewCommonBlockItem } from "app/components/header/sub-components/expanded-view/data";

export const SignInMethods = [
  {
    title: "Sign in with Google",
    icon: <GoogleIcon />,
  },
  {
    title: "Sign in with LinkedIn",
    icon: <LinkedinIcon />,
  },
  {
    title: "Sign in with Microsoft",
    icon: <MicrosoftIcon />,
  },
];

export const HeaderSignInView: React.FC = () => {
  const [signedIn, setSignedIn] = React.useState(false);

  const handleSignInButtonClick = () => {
    setSignedIn(true);
  };

  const handleLogOutButtonClick = () => {
    setSignedIn(false);
  };

  const view = React.useMemo(() => {
    if (signedIn) {
      return (
        <React.Fragment>
          <Typography variant="h5" fontSize="14px" marginBottom="20px">
            Hello Jane. You are signed in.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img src={ProfilePicture} alt="Profile" />
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  "> div": {
                    gap: "5px",
                    display: "flex",
                    minWidth: "150px",
                    flexDirection: "column",
                  },
                }}
              >
                <Box>
                  <Typography variant="h6" fontSize="12px" fontWeight="400">
                    Organisation
                  </Typography>
                  <Typography variant="h5" fontSize="14px">
                    Zimmerman
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" fontSize="12px" fontWeight="400">
                    Plan
                  </Typography>
                  <Typography variant="h5" fontSize="14px">
                    Standard
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" fontSize="12px" fontWeight="400">
                    Publisher identifier
                  </Typography>
                  <Typography variant="h5" fontSize="14px">
                    GB-CXC-1187009
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                startIcon={<LogOutIcon />}
                onClick={handleLogOutButtonClick}
                sx={{
                  gap: "10px",
                  height: "32px",
                  display: "flex",
                  fontSize: "12px",
                  fontWeight: "700",
                  borderRadius: "5px",
                  alignItems: "center",
                  textTransform: "none",
                  justifyContent: "center",
                  color: colors.primary.white,
                  background: colors.secondary.red,
                  ":hover": {
                    background: colors.secondary.darkRed,
                  },
                  "> .MuiButton-icon": {
                    margin: 0,
                  },
                }}
              >
                Log Out
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Typography variant="h5" fontSize="14px" marginBottom="40px">
          Quick Log in Actions
        </Typography>
        <Box
          sx={{
            gap: "30px",
            display: "flex",
            flexDirection: "row",
            "> button": {
              gap: "10px",
              width: "190px",
              height: "32px",
              display: "flex",
              fontSize: "12px",
              fontWeight: "700",
              borderRadius: "5px",
              alignItems: "center",
              textTransform: "none",
              justifyContent: "center",
              color: colors.primary.white,
              background: colors.primary.blue,
              ":hover": {
                background: colors.shades.blue[400],
              },
            },
          }}
        >
          {SignInMethods.map((method) => (
            <Button
              startIcon={method.icon}
              key={method.title}
              onClick={handleSignInButtonClick}
            >
              {method.title}
            </Button>
          ))}
        </Box>
      </React.Fragment>
    );
  }, [signedIn]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        "> div": {
          paddingTop: "0px !important",
        },
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          borderRight: "1px solid #A1AEBD",
          "> div:hover": {
            cursor: "default",
            background: "transparent",
            borderColor: "transparent",
            h5: {
              color: colors.text.title,
            },
          },
        }}
      >
        <Typography variant="h5" fontSize="14px" marginBottom="24px">
          See Your Account & Log in
        </Typography>
        <HeaderViewCommonBlockItem
          title="My Account"
          icon={<MyAccountIcon />}
          subtitle="Connect to your account & see your settings."
        />
      </Grid>
      <Grid item spacing={2} xs={12} sm={8}>
        {view}
      </Grid>
    </Grid>
  );
};
