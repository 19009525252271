export const ORGANISATIONS = [
  {
    name: "Aapasi Sahayog Kendra Syangja Nepal (ASK Nepal)",
    iatiIdentifier: "NP-SWC-8220",
    organisationType: "National NGO",
    organisationHQ: "Nepal",
    totalDatasets: 2,
    totalActivities: 2,
  },
  {
    name: "Aaranyak",
    iatiIdentifier: "NP-REG-002",
    organisationType: "National NGO",
    organisationHQ: "Nepal",
    totalDatasets: 2,
    totalActivities: 2,
  },
  {
    name: "Aasaman Nepal",
    iatiIdentifier: "NP-REG-001",
    organisationType: "National NGO",
    organisationHQ: "Nepal",
    totalDatasets: 2,
    totalActivities: 2,
  },
  {
    name: "Aashish Nepal",
    iatiIdentifier: "NP-REG-003",
    organisationType: "National NGO",
    organisationHQ: "Nepal",
    totalDatasets: 2,
    totalActivities: 2,
  },
  {
    name: "Aasman Nepal",
    iatiIdentifier: "NP-REG-004",
    organisationType: "National NGO",
    organisationHQ: "Nepal",
    totalDatasets: 2,
    totalActivities: 2,
  },
];
