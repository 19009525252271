import React from "react";

export const SuccessIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3931_39183)">
        <path
          d="M9.41714 14.9313L9.64301 15.1547L9.86899 14.9315L17.9637 6.93626L18.6145 7.57964L9.64312 16.4491L5.70898 12.5596L6.36589 11.9148L9.41714 14.9313Z"
          fill="#454545"
          stroke="#454545"
          stroke-width="0.642857"
        />
      </g>
      <circle
        cx="12"
        cy="12"
        r="11.6786"
        stroke="#454545"
        stroke-width="0.642857"
      />
      <defs>
        <clipPath id="clip0_3931_39183">
          <rect
            width="18.8571"
            height="18.6429"
            fill="white"
            transform="translate(2.57129 2.14258)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
