import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinkedIn from "@mui/icons-material/LinkedIn";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { ReactComponent as HeaderToolbarLogo } from "app/assets/vectors/HeaderToolbarLogo.svg";
import { ReactComponent as FooterLinkAIDAExplorer } from "app/assets/vectors/FooterLinkAIDAExplorer.svg";
import { ReactComponent as FooterLinkDiscoverSectors } from "app/assets/vectors/FooterLinkDiscoverSectors.svg";
import { ReactComponent as FooterLinkDiscoverLocations } from "app/assets/vectors/FooterLinkDiscoverLocations.svg";
import { ReactComponent as FooterLinkDiscoverOrganisations } from "app/assets/vectors/FooterLinkDiscoverOrganisations.svg";
import { ReactComponent as FooterLinkSearchFilterActivities } from "app/assets/vectors/FooterLinkSearchFilterActivities.svg";

const FooterEl = styled.footer`
  width: 100vw;
  background: #f7f7f7;
  border-top: 1px solid ${colors.secondary.grayText};
`;

const links = [
  {
    label: "AIDA Explorer",
    link: "/explorer",
    bgIcon: <FooterLinkAIDAExplorer />,
  },
  {
    label: "Discover Locations",
    link: "/explorer/locations",
    bgIcon: <FooterLinkDiscoverLocations />,
  },
  {
    label: "Discover Organisations",
    link: "/explorer/organisations",
    bgIcon: <FooterLinkDiscoverOrganisations />,
  },
  {
    label: "Discover Sectors",
    link: "/explorer/sectors",
    bgIcon: <FooterLinkDiscoverSectors />,
  },
  {
    label: "Search & Filter Activities",
    link: "/explorer/activities",
    bgIcon: <FooterLinkSearchFilterActivities />,
  },
  {
    label: "Join the monthly AIDA Webinar",
    link: "/",
  },
];

export const Footer: React.FC = () => {
  const location = useLocation();

  const isPublisher = React.useMemo(() => {
    return location.pathname.indexOf("/publisher") > -1;
  }, [location.pathname]);

  return (
    <FooterEl>
      <Container
        maxWidth="lg"
        sx={{
          height: "245px",
          padding: "20px 0 !important",
        }}
      >
        <Box
          sx={{
            gap: "20px",
            width: "100%",
            height: "64px",
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          {links.map((link, index) => (
            <CtaCommonBtn
              {...link}
              key={link.label}
              variant="footer"
              bgColor={
                isPublisher && index === links.length - 1 ? "green" : "blue"
              }
            />
          ))}
        </Box>
        <Box
          sx={{
            gap: "20px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              maxWidth: "300px",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <HeaderToolbarLogo />
            <Typography fontSize="12px" color={colors.text.title}>
              A comprehensive solution that effortlessly links with the IATI
              Registry, verifies your data, and transforms your IATI information
              into compelling visualizations.
            </Typography>
            <IconButton
              sx={{
                padding: 0,
              }}
            >
              <LinkedIn htmlColor={colors.text.title} />
            </IconButton>
          </Box>
          <Box
            sx={{
              gap: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              "> input": {
                width: "268px",
                height: "32px",
                fontSize: "12px",
                padding: "0 20px",
                borderRadius: "5px",
                color: colors.text.title,
                background: colors.shades[isPublisher ? "green" : "blue"][100],
                border: `1px solid ${colors.text.title}`,
                "::placeholder": {
                  color: colors.text.title,
                },
                ":focus": {
                  outline: "none",
                },
              },
            }}
          >
            <Typography variant="h6" fontSize="12px">
              Subscribe to our newsletter
            </Typography>
            <input type="text" placeholder="Email" />
            <Button
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                textTransform: "none",
                color: colors.primary.white,
                background: isPublisher
                  ? colors.primary.green
                  : colors.primary.blue,
              }}
              variant="contained"
              endIcon={<ArrowForward />}
            >
              Subscribe
            </Button>
          </Box>
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              "> a": {
                fontSize: "12px",
                textDecoration: "none",
                color: colors.text.title,
              },
            }}
          >
            <Link to="/">Homepage</Link>
            <Link to="/data-services">Data Services</Link>
            <Link to="/plans-pricing">Plans & Pricing</Link>
          </Box>
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              "> a": {
                fontSize: "12px",
                textDecoration: "none",
                color: colors.text.title,
              },
            }}
          >
            <Link to="/user-guide">User Guide</Link>
            <Link to="/my-account">My Account</Link>
          </Box>
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              "> a": {
                fontSize: "12px",
                textDecoration: "none",
                color: colors.text.title,
              },
            }}
          >
            <Typography fontSize="12px" color={colors.text.title}>
              The Open Data Company B.V.
              <br />
              Herengracht 449a,
              <br />
              1017 BR Amsterdam,
              <br />
              The Netherlands
            </Typography>
            <a href="tel:+31205039091">+31 20 503 9091</a>
            <a href="mailto:info@aida.tools">info@aida.tools</a>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          gap: "20px",
          width: "100%",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: colors.secondary.grayText,
          background: colors.secondary.disableText,
          "> a": {
            fontSize: "12px",
            textDecoration: "none",
            color: colors.secondary.grayText,
          },
        }}
      >
        <Typography fontSize="18px" color={colors.secondary.grayText}>
          ©
        </Typography>
        <Typography fontSize="12px" color={colors.secondary.grayText}>
          {new Date().getFullYear()} Zimmerman B.V. All Rights Reserved
        </Typography>
        <Typography fontSize="12px" color={colors.secondary.grayText}>
          Privacy Policy
        </Typography>
        <Typography fontSize="12px" color={colors.secondary.grayText}>
          ·
        </Typography>
        <Link to="/privacy">Privacy</Link>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
        <Link to="/disclaimer">Disclaimer</Link>
      </Box>
    </FooterEl>
  );
};
