import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { PieSeriesOption, PieChart as EChartsPie } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { PieChartProps } from "app/components/charts/pie/data";
import { formatNumber } from "app/utils/formatNumber";

echarts.use([EChartsPie, SVGRenderer, GridComponent]);

export const PieChart: React.FC<PieChartProps> = (props: PieChartProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: `${props.id}-pie-chart`,
  });

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<PieSeriesOption> = {
        color: colors.charts,
        series: {
          type: "pie",
          endAngle: props.half ? 360 : "auto",
          startAngle: props.half ? 180 : 0,
          data: props.data,
          center: ["50%", props.half ? "70%" : "50%"],
          radius: props.donut ? ["60%", "100%"] : ["0%", "100%"],
          bottom: props.half ? -50 : 20,
          padAngle: 0.2,
          top: props.half ? 0 : 20,
          label: {
            show: true,
            fontSize: props.labelFontSize ?? "12px",
            minMargin: 0,
            fontFamily: "'Inter', sans-serif",
            formatter: (params) => {
              return `${params.name}\n${props.unit} ${formatNumber(params.value as number)}\n(${params.percent}%)`;
            },
            rich: {
              boldName: {
                fontSize: "14px",
                fontWeight: "bold",
              },
            },
          },
        },
      };

      chart.setOption(option);
      setStateChart(chart);
    }
  }, [
    containerRef.current,
    props.data,
    props.half,
    props.donut,
    props.unit,
    props.height,
    props.labelFontSize,
  ]);

  return (
    <Box
      id={`${props.id}-pie-chart`}
      ref={containerRef}
      width="100%"
      height={props?.height ?? "450px"}
    />
  );
};
